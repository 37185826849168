import { useRef, useState } from "react";
import FocusLock from "react-focus-lock";
import { Trans, useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { BellIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverContent,
  PopoverBody,
  IconButton,
  Tooltip,
  VStack,
  Text,
  Flex,
  Spacer,
  Skeleton,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import {
  useFetchNotifications,
  useReadNotification,
  useReadNotifications,
} from "lib/notification/api";
import { Notification, NotificationStatus } from "lib/notification/type";
import { useAcceptInvitation, useRefuseInvitation } from "lib/room/api";

type Props = {
  badgeStatus: NotificationStatus;
  handleUpdateBadge: () => void;
  notifications: Notification[];
  isLoading: boolean;
};

export const NotificationPopover: React.FC<Props> = (props) => {
  const { notifications, badgeStatus, handleUpdateBadge, isLoading } = props;
  const { t } = useTranslation("notifications");
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef(null);

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={() => {
        onOpen();
        handleUpdateBadge();
      }}
      onClose={onClose}
      placement="bottom-end"
      closeOnBlur={false}
    >
      <Tooltip label={t("notifications")}>
        {/* additional element */}
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              icon={
                <Box variant="unstyled">
                  <BellIcon w={5} h={5} />
                  <Box
                    bgColor="aisekiYellow.500"
                    borderColor="aisekiWhite.500"
                    borderWidth={2}
                    borderRadius="full"
                    w={3}
                    h={3}
                    position="absolute"
                    top={2}
                    right={1.5}
                    hidden={badgeStatus === "read"}
                  />
                </Box>
              }
              variant="ghost"
              aria-label="Notifications"
              size="md"
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent boxShadow="xl">
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverHeader fontWeight="bold">{t("notifications")}</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody overflowY="auto" maxHeight="md">
            <VStack spacing={2}>
              {isLoading ? (
                <RoomNotificationSkeleton isLoading={isLoading} />
              ) : notifications && notifications.length < 1 ? (
                <RoomNotificationEmptyUI />
              ) : (
                notifications?.map((notification) => (
                  <NotificationItem
                    notification={notification}
                    onClosePopover={onClose}
                    key={notification.notificationId}
                  />
                ))
              )}
            </VStack>
          </PopoverBody>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

type UseNotificationItemResult = {
  message: React.ReactElement | string;
  overrideStatus?: NotificationStatus;
  primaryAction?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    hidden?: boolean;
    isLoading?: boolean;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    hidden?: boolean;
    isLoading?: boolean;
  };
};

const useNotificationItem = (
  notification: Notification
): UseNotificationItemResult => {
  const router = useRouter();
  const { t } = useTranslation("notifications");
  const toast = useToast();

  const readNotification = useReadNotification();
  const acceptInvitation = useAcceptInvitation();
  const refuseInvitation = useRefuseInvitation();

  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);

  switch (notification.type) {
    case "transcodeCompleted":
      return {
        message: (
          <Trans t={t} i18nKey={notification.type}>
            動画「
            {{ title: notification.video.title }}
            」のトランスコードが完了しました
          </Trans>
        ),
        primaryAction: {
          label: t("watchAction"),
          onClick: async () => {
            await readNotification(notification.notificationId);
            location.href = `/videos/${notification.video.videoId}`;
          },
        },
      };
    case "roomVideoUploaded":
      return {
        message: (
          <Trans t={t} i18nKey={notification.type}>
            ルーム「
            {{ roomName: notification.room.name }}
            」に動画「
            {{ title: notification.video.title }}
            」が投稿されました
          </Trans>
        ),
        primaryAction: {
          label: t("watchAction"),
          onClick: async () => {
            await readNotification(notification.notificationId);
            location.href = `/videos/${notification.video.videoId}`;
          },
        },
      };
    // TODO: 新たに通知されることはないので削除する
    case "postAudioComment":
      return {
        message: (
          <Trans t={t} i18nKey={notification.type}>
            録音コメントの投稿が完了しました
          </Trans>
        ),
        primaryAction: {
          label: t("watchAction"),
          onClick: async () => {
            await readNotification(notification.notificationId);
            location.href = `/videos/${notification.audioComment.videoId}`;
          },
        },
      };
    case "paint":
      return {
        message: (
          <Trans t={t} i18nKey={notification.type}>
            フィードバックの投稿が完了しました
          </Trans>
        ),
        // TODO: バックエンドからフィードバックに関するリソース情報が返ってきたら対応
        // primaryAction: {
        //   label: t("watchAction"),
        //   onClick: async () => {
        //     await router.push(`/videos/${notification?.paintComment.videoId}`);
        //     readNotification(notification.notificationId);
        //   },
        // },
      };
    case "roomInvitation":
      return {
        message: (
          <Trans t={t} i18nKey={notification.type}>
            {{ inviter: notification.invitation.displayName }}
            さんからルーム「
            {{ roomName: notification.invitation.roomName }}
            」への招待が届きました
          </Trans>
        ),
        overrideStatus:
          notification.invitation.status === "waiting" ? "unread" : "read",
        primaryAction: {
          label:
            notification.invitation.status === "waiting"
              ? t("acceptAction")
              : t("invitationStatus." + notification.invitation.status),
          onClick: async () => {
            setIsPrimaryLoading(true);
            try {
              await acceptInvitation(notification.invitation.invitationId);
              await router.push(`/rooms/${notification.invitation.roomId}`);
              await readNotification(notification.notificationId);
              toast({
                title: "ルームに参加しました",
                status: "success",
                position: "top",
                isClosable: true,
              });
            } catch (e) {
              toast({
                title: "ルームへの参加に失敗しました 再度お試しください",
                status: "error",
                position: "top",
                isClosable: true,
              });
            }
            setIsPrimaryLoading(false);
          },
          disabled: notification.invitation.status !== "waiting",
          isLoading: isPrimaryLoading,
        },
        secondaryAction: {
          label: t("refuseAction"),
          onClick: async () => {
            setIsSecondaryLoading(true);
            try {
              await refuseInvitation(notification.invitation.invitationId);
              await readNotification(notification.notificationId);
              toast({
                title: "ルームへの招待を拒否しました",
                status: "success",
                position: "top",
                isClosable: true,
              });
            } catch (e) {
              toast({
                title:
                  "ルームへの招待を拒否できませんでした 再度お試しください",
                status: "error",
                position: "top",
                isClosable: true,
              });
            }
            setIsSecondaryLoading(false);
          },
          disabled: notification.invitation.status !== "waiting",
          hidden: notification.invitation.status !== "waiting",
          isLoading: isSecondaryLoading,
        },
      };
    default:
      return {
        message: "",
      };
  }
};

type NotificationItemProps = {
  notification: Notification;
  onClosePopover: () => void;
};

const NotificationItem: React.FC<NotificationItemProps> = (props) => {
  const { notification, onClosePopover } = props;
  const { message, primaryAction, secondaryAction, overrideStatus } =
    useNotificationItem(notification);

  const isRead = notification.status === "read" || overrideStatus === "read";

  return (
    <Box borderRadius="md" variant="solid" bg="aisekiGray.100" w="full" p="2">
      <Flex>
        <Text
          fontSize="xs"
          fontWeight={isRead ? "normal" : "bold"}
          flexGrow={1}
          mb={2}
        >
          {message}
        </Text>
        <Box
          bgColor="aisekiBlue.500"
          borderColor="aisekiWhite.500"
          borderRadius="full"
          hidden={isRead}
          w={2}
          h={2}
          flexShrink={0}
          m={1}
        />
      </Flex>
      <Flex>
        <Spacer />
        {!!secondaryAction && (
          <Button
            size="sm"
            fontSize="xs"
            color="aisekiBlue.500"
            isLoading={secondaryAction.isLoading}
            onClick={secondaryAction.onClick}
            disabled={secondaryAction.disabled}
            hidden={secondaryAction.hidden}
            ml={2}
          >
            {secondaryAction.label}
          </Button>
        )}
        {!!primaryAction && (
          <Button
            size="sm"
            fontSize="xs"
            colorScheme="aisekiBlue"
            isLoading={primaryAction.isLoading}
            onClick={primaryAction.onClick}
            disabled={primaryAction.disabled}
            hidden={primaryAction.hidden}
            ml={2}
          >
            {primaryAction.label}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

const RoomNotificationEmptyUI: React.FC = (props) => (
  <Box borderRadius="md" variant="solid" bg="aisekiGray.100" p="4" width="100%">
    <Text fontSize="xs">未読の通知はありません。</Text>
  </Box>
);

const RoomNotificationSkeleton: React.FC<{ isLoading: boolean }> = (props) => {
  const { isLoading } = props;
  return (
    <>
      {[...Array(5)].map((v, i) => (
        <Box key={`notification-item-${i}`} variant="unstyled" w="full" p={2}>
          <SkeletonText isLoaded={!isLoading} noOfLines={2} />
          <Flex mt={4}>
            <Spacer />
            <Skeleton
              isLoaded={!isLoading}
              borderRadius="md"
              width="4rem"
              height="2rem"
              ml={2}
            />
          </Flex>
        </Box>
      ))}
    </>
  );
};

export const RoomNotificationPopoverContainer = () => {
  const { notifications, badgeStatus, isLoading, error } =
    useFetchNotifications();
  const readNotifications = useReadNotifications();

  return (
    <NotificationPopover
      badgeStatus={badgeStatus}
      notifications={notifications || []}
      isLoading={isLoading}
      handleUpdateBadge={readNotifications}
    />
  );
};
