import { useToast as useToastChakra, UseToastOptions } from "@chakra-ui/react";

const defaultOptions: UseToastOptions = {
  position: "top",
  isClosable: true,
};

export const useToast = () => {
  const toast = useToastChakra();

  return {
    successToast: (options?: UseToastOptions) =>
      toast({ ...defaultOptions, ...options, status: "success" }),
    infoToast: (options?: UseToastOptions) =>
      toast({ ...defaultOptions, ...options, status: "info" }),
    warningToast: (options?: UseToastOptions) =>
      toast({ ...defaultOptions, ...options, status: "warning" }),
    errorToast: (options?: UseToastOptions) =>
      toast({ ...defaultOptions, ...options, status: "error" }),
  };
};
