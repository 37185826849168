// 招待一覧取得API

import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import { useAuthState } from "@bonbon/data-access-auth";
import { apiHost } from "lib/util/fetch";
import { Notification, NotificationStatus } from "./type";

type FetchNotificationResponse = {
  notification: {
    status: NotificationStatus;
    notifyEvents: Notification[];
  };
};

export const useFetchNotifications = () => {
  const { currentUser, idToken } = useAuthState();

  const { data, error, isValidating } = useSWR<
    FetchNotificationResponse["notification"]
  >(
    [`https://${apiHost}/aiseki/api/notifications`, currentUser?.id],
    async (url) => {
      const {
        data: { notification },
      } = await axios.get<FetchNotificationResponse>(url, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return notification;
    },
    {
      refreshInterval: 60000,
    }
  );

  return {
    notifications: data?.notifyEvents || [],
    badgeStatus: data?.status || "read",
    isLoading: (!data && !error) || isValidating,
    error: error,
  };
};

// 通知既読API（通知イベントごと）

export const useReadNotification = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();

  return async (notificationCode: string) => {
    return mutate<void>(
      [
        `https://${apiHost}/aiseki/api/notifications/${notificationCode}`,
        currentUser?.id,
      ],
      async () => {
        await axios.put<void>(
          `https://${apiHost}/aiseki/api/notifications/${notificationCode}`,
          null,
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        mutate([
          `https://${apiHost}/aiseki/api/notifications`,
          currentUser?.id,
        ]);
      }
    );
  };
};

// 通知既読API（ベルマークの黄色いバッヂステータス）

export const useReadNotifications = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();

  return async () => {
    return mutate<FetchNotificationResponse["notification"]>(
      [`https://${apiHost}/aiseki/api/notifications`, currentUser?.id],
      async (notifications) => {
        await axios.put<void>(
          `https://${apiHost}/aiseki/api/notifications`,
          null,
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        return {
          status: "read",
          notifyEvents: notifications?.notifyEvents || [],
        };
      }
    );
  };
};
