import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Center, Icon, Text, IconButton, Flex } from "@chakra-ui/react";
import { IoMdAdd } from "react-icons/io";
import { MdRoom } from "react-icons/md";
import { LayoutProps } from "components/layout";
import { useOrganizationContext } from "lib/organization/organization-context";

type RoomColumnProps = Pick<LayoutProps, "whichIsActive" | "onOpenCreateModal">;

const RoomColumn = ({ whichIsActive, onOpenCreateModal }: RoomColumnProps) => {
  const { currentOrganization } = useOrganizationContext();
  const { t } = useTranslation();
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      h={12}
      p={2}
      bgColor={whichIsActive === "room" ? "aisekiYellow.500" : "aisekiNavy.500"}
      color={whichIsActive === "room" ? "aisekiBlack.500" : "aisekiWhite.500"}
    >
      <Center>
        <Icon as={MdRoom} w={5} h={5} />
        <Text ml={4}>{t("common:room")}</Text>
      </Center>
      <IconButton
        icon={<Icon as={IoMdAdd} w={5} h={5} />}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          onOpenCreateModal();
          e.preventDefault();
        }}
        hidden={currentOrganization?.role === "guest"}
        aria-label="ルームを追加する"
        colorScheme="transparent"
        size="xs"
        color={whichIsActive === "room" ? "aisekiBlack.500" : "aisekiWhite.500"}
      />
    </Flex>
  );
};

export default RoomColumn;
