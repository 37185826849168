import React, { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import { useAuthState } from "@bonbon/data-access-auth";
import {
  usePagination,
  useRoomMemberPagination,
} from "components/pagination/hooks";
import { useOrganizationContext } from "lib/organization/organization-context";
import { apiHost } from "lib/util/fetch";
import { Video, VideoUser } from "lib/video/type";
import {
  getUploadUrl,
  startChunkUpload,
  chunkUpload,
  checkResumableUpload,
  createRoomVideo,
  updateVideo,
  deleteVideo,
} from "lib/video/upload/action";
import { useLocalStorageVideoData } from "lib/video/upload/hooks";
import {
  CreateVideoRequestPayload,
  updateVideoPayload,
  VideoUploadStatus,
} from "lib/video/upload/type";
import { Organization } from "./../organization/api";
import { Invitation, Member, Room } from "./type";

// ルーム一覧取得API

type FetchRoomsResponse = {
  rooms: Room[];
};
export const useFetchRooms = () => {
  const { currentUser, idToken } = useAuthState();
  const { currentOrganization } = useOrganizationContext();
  const cacheKey =
    currentUser?.id && currentOrganization
      ? [`https://${apiHost}/aiseki/api/rooms`, currentOrganization.id]
      : null;

  const { data, error } = useSWR(cacheKey, async (url) => {
    const {
      data: { rooms },
    } = await axios.get<FetchRoomsResponse>(url, {
      params: { organizationId: currentOrganization?.id },
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return rooms;
  });

  return {
    rooms: data || [],
    isLoading: !!cacheKey && !data && !error,
    error,
  };
};

// ルーム取得API

export const useFetchRoom = (roomId: string) => {
  const { currentUser, idToken } = useAuthState();
  const { currentOrganization } = useOrganizationContext();
  const cacheKey =
    roomId && currentUser?.id && currentOrganization
      ? [
          `https://${apiHost}/aiseki/api/rooms/${roomId}`,
          currentOrganization.id,
        ]
      : null;

  const { data, error, mutate } = useSWR(cacheKey, async () => {
    const {
      data: { rooms },
    } = await axios.get<FetchRoomsResponse>(
      `https://${apiHost}/aiseki/api/rooms`,
      {
        params: { organizationId: currentOrganization?.id },
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return rooms.find((r) => r.roomId === roomId);
  });

  return {
    room: data,
    error,
    refresh: () => mutate(),
    isLoading: !!cacheKey && !data && !error,
  };
};

// ルーム作成API

type CreateRoomResponse = {
  room: Room;
};
type CreateRoomRequestPayload = {
  name: string;
  inviteUserEmailAddresses: string[];
};
export const useCreateRoom = () => {
  const { currentUser, idToken } = useAuthState();
  const { currentOrganization } = useOrganizationContext();

  const { mutate } = useSWRConfig();
  const [isCreating, setIsCreating] = React.useState(false);
  const [createdRoom, setCreatedRoom] = React.useState<Room | null>(null);
  const cacheKey =
    currentUser?.id && currentOrganization
      ? [`https://${apiHost}/aiseki/api/rooms`, currentOrganization.id]
      : null;

  const createRoomMutate = async (payload: CreateRoomRequestPayload) => {
    return mutate<Room[]>(cacheKey, async (rooms) => {
      setIsCreating(true);
      const {
        data: { room: createdRoom },
      } = await axios.post<CreateRoomResponse>(
        `https://${apiHost}/aiseki/api/rooms`,
        {
          payload: {
            ...payload,
            organizationId: currentOrganization?.id,
          },
        },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsCreating(false);

      setCreatedRoom(createdRoom);
      return [...(rooms || []), createdRoom];
    });
  };

  return {
    createRoomMutate: createRoomMutate,
    isCreating: isCreating,
    createdRoom: createdRoom,
    cleanCreatedRoom: () => {
      setCreatedRoom(null);
    },
  };
};

// ルーム編集API

type UpdateRoomResponse = {
  room: Room;
};
type UpdateRoomRequestPayload = {
  name: string;
};
export const useUpdateRoom = () => {
  const { mutate } = useSWRConfig();
  const { currentUser, idToken } = useAuthState();
  const { currentOrganization } = useOrganizationContext();

  const [isUpdating, setIsUpdating] = React.useState(false);
  const [updatedRoom, setUpdatedRoom] = React.useState<Room | null>(null);
  const cacheKey =
    currentUser?.id && currentOrganization
      ? [`https://${apiHost}/aiseki/api/rooms`, currentOrganization.id]
      : null;

  const updateRoomMutate = async (
    roomId: string,
    payload: UpdateRoomRequestPayload
  ) => {
    return mutate<Room[]>(cacheKey, async (rooms) => {
      setIsUpdating(true);
      const {
        data: { room: updatedRoom },
      } = await axios.put<UpdateRoomResponse>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}`,
        { payload: payload },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsUpdating(false);

      setUpdatedRoom(updatedRoom);
      currentOrganization &&
        mutate([
          `https://${apiHost}/aiseki/api/rooms/${roomId}`,
          currentOrganization.id,
        ]);

      const filteredRooms =
        rooms?.filter((room) => room.roomId != updatedRoom.roomId) || [];
      return [...filteredRooms, updatedRoom];
    });
  };

  return {
    updateRoomMutate: updateRoomMutate,
    isUpdating: isUpdating,
    updatedRoom: updatedRoom,
    cleanCreatedRoom: () => {
      setUpdatedRoom(null);
    },
  };
};

// ルーム削除API

export const useDeleteRoom = () => {
  const { mutate } = useSWRConfig();
  const { currentUser, idToken } = useAuthState();
  const { currentOrganization } = useOrganizationContext();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const cacheKey =
    currentUser?.id && currentOrganization
      ? [`https://${apiHost}/aiseki/api/rooms`, currentOrganization.id]
      : null;

  const deleteRoomMutate = async (roomId: Room["roomId"]) => {
    return mutate<Room[]>(cacheKey, async (rooms) => {
      setIsDeleting(true);
      await axios.delete<void>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsDeleting(false);

      return rooms?.filter((room) => room.roomId != roomId) || [];
    });
  };

  return {
    deleteRoomMutate: deleteRoomMutate,
    isDeleting: isDeleting,
  };
};

// メンバー一覧取得API

type FetchRoomMembersResponse = {
  members: Omit<Member, "status">[];
  inviteMembers: Member[];
  paging: {
    totalCount: number;
    limit: number;
    offset: number;
  };
};
/**`/rooms/${roomId}` */
export const useFetchRoomMembers = (roomId: string | undefined) => {
  const { currentUser, idToken } = useAuthState();
  const {
    currentPage,
    offset,
    perPage,
    onChangePerPage,
    onChangePage,
    onResetRoomPaginationData,
  } = useRoomMemberPagination();
  const cacheKey =
    roomId && currentUser?.id
      ? `https://${apiHost}/aiseki/api/rooms/${roomId}/members?limit=${perPage}&offset=${offset}`
      : null;
  const { data, error, isValidating } = useSWR(cacheKey, async (url) => {
    const {
      data: { paging, members, inviteMembers },
    } = await axios.get<FetchRoomMembersResponse>(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });

    return {
      members: [
        ...members.map<Member>((m) => ({ ...m, status: "joined" as const })),
        ...inviteMembers.map<Member>((m) => ({
          ...m,
          displayName: m.emailAddress,
        })),
      ],
      paging: paging,
    };
  });

  return {
    members: data?.members || [],
    offset: offset,
    perPage: perPage,
    totalCount: data?.paging.totalCount || 0,
    currentPage: currentPage,
    isLoading: (!data && !error) || isValidating,
    error: error,
    onChangePerPage,
    onChangePage,
    onResetRoomPaginationData,
  };
};

// メンバー削除API

export const useDeleteRoomMember = (roomId: Room["roomId"] | undefined) => {
  const { mutate } = useSWRConfig();
  const { currentUser, idToken } = useAuthState();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const cacheKey =
    currentUser?.id && roomId
      ? `https://${apiHost}/aiseki/api/rooms/${roomId}/members`
      : null;

  const deleteRoomMemberMutate = async (
    memberId: Member["bonbonUserId"] | undefined
  ) => {
    return mutate<Member[]>(cacheKey, async (members) => {
      setIsDeleting(true);
      await axios.delete<void>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}/members/${memberId}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsDeleting(false);

      return members?.filter((m) => m.bonbonUserId !== memberId) || [];
    });
  };

  return {
    deleteRoomMemberMutate: deleteRoomMemberMutate,
    isDeleting: isDeleting,
  };
};

// 招待一覧取得API
type FetchInvitationsResponse = {
  invitations: Invitation[];
};
export const useFetchInvitations = () => {
  const { currentUser, idToken } = useAuthState();

  const { data, error, isValidating } = useSWR(
    currentUser?.id ? `https://${apiHost}/aiseki/api/invitations` : null,
    async (url) => {
      const {
        data: { invitations },
      } = await axios.get<FetchInvitationsResponse>(url, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return invitations;
    }
  );

  return {
    invitations: data || [],
    isLoading: (!data && !error) || isValidating,
    error: error,
  };
};

// ルーム招待API
type SendInvitationResponse = {
  room: Room;
};
type SendInvitationRequestPayload = {
  inviteUserEmailAddresses: string[];
};
export const useSendInvitation = (roomId: string | undefined) => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isSending, setIsSending] = React.useState(false);
  const cacheKey =
    currentUser?.id && roomId
      ? `https://${apiHost}/aiseki/api/rooms/${roomId}/members`
      : null;

  const sendInvitationMutate = async (
    payload: SendInvitationRequestPayload
  ) => {
    return mutate<Member[]>(cacheKey, async (members) => {
      setIsSending(true);
      await axios.post<SendInvitationResponse>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}/invitations`,
        { payload: payload },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsSending(false);
      return members;
    });
  };

  return {
    sendInvitationMutate: sendInvitationMutate,
    isSending: isSending,
  };
};

type AcceptSharedLinkResponse = {
  result: {
    invitationCode: string;
    roomId: Room["roomId"];
    roomName: Room["name"];
    organizationId: Organization["id"];
  };
};

// ルーム公開リンク受諾API
export const useAcceptSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const { currentOrganization } = useOrganizationContext();

  return async (
    roomId: Room["roomId"],
    invitationCode: RoomSharedLink["invitationCode"]
  ) => {
    const cacheKey =
      roomId && invitationCode && currentUser?.id
        ? `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation/${invitationCode}`
        : null;

    return mutate<AcceptSharedLinkResponse["result"]>(cacheKey, async () => {
      const { data } = await axios.post<AcceptSharedLinkResponse>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation/${invitationCode}`,
        null,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      await mutate(`https://${apiHost}/aiseki/api/organizations`);
      await mutate([
        `https://${apiHost}/aiseki/api/rooms`,
        currentOrganization?.id,
      ]);
      return data.result;
    });
  };
};

// ルーム招待受諾API
export const useAcceptInvitation = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const { currentOrganization } = useOrganizationContext();

  return async (invitationId: string) => {
    return mutate<void>(
      currentUser?.id
        ? `https://${apiHost}/aiseki/api/invitations/${invitationId}`
        : null,
      async () => {
        await axios.post<SendInvitationResponse>(
          `https://${apiHost}/aiseki/api/invitations/${invitationId}`,
          null,
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        await mutate(`https://${apiHost}/aiseki/api/organizations`);
        await mutate([
          `https://${apiHost}/aiseki/api/rooms`,
          currentOrganization?.id,
        ]);
      }
    );
  };
};

// ルーム招待拒否API
export const useRefuseInvitation = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const { currentOrganization } = useOrganizationContext();

  return async (invitationId: string) => {
    return mutate<void>(
      currentUser?.id
        ? `https://${apiHost}/aiseki/api/invitations/${invitationId}`
        : null,
      async () => {
        await axios.delete<SendInvitationResponse>(
          `https://${apiHost}/aiseki/api/invitations/${invitationId}`,
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        await mutate(`https://${apiHost}/aiseki/api/organizations`);
        await mutate([
          `https://${apiHost}/aiseki/api/rooms`,
          currentOrganization?.id,
        ]);
      }
    );
  };
};

// ルーム内動画取得API

type FetchRoomVideosResponse = {
  videos: Video[];
  paging: {
    totalCount: number;
    limit: number;
    offset: number;
  };
  users: VideoUser[];
};

export const useFetchRoomVideos = (roomId: string) => {
  const { currentUser, idToken } = useAuthState();
  const { offset, perPage } = usePagination();
  const { data, error, isValidating } = useSWR<FetchRoomVideosResponse>(
    [
      `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`,
      currentUser?.id,
    ],
    async () => {
      const {
        data: { videos, paging, users },
      } = await axios.get<FetchRoomVideosResponse>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      return { videos, paging, users };
    }
  );

  return {
    videos: data?.videos || [],
    users: data?.users || [],
    totalCount: data?.paging.totalCount || 0,
    isLoading: (!data && !error) || isValidating,
    error,
    offset,
    perPage,
  };
};

export const useUploadRoomVideo = (
  offset: number,
  perPage: number,
  readyToEncode: (roomId: string, videoId: string) => void,
  roomId: string //ここで入れたので、関数から排除していい
) => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [status, setStatus] = React.useState<VideoUploadStatus>("idle");
  const [start, setStart] = useState<number>(0);
  const [file, setFile] = useState<File | undefined>(undefined);
  const CHUNKSIZE = 1024 * 1024 * 8; // 8MB
  const [persentage, setPersentage] = useState<number>(0);
  const { saveUploadVideoData, getUploadVideoData, clearUploadVideoData } =
    useLocalStorageVideoData();
  const updateProgress = (progress: number) => {
    setPersentage(progress);
  };
  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    if (status === "uploading" && file) {
      const _chunkEnd = Math.min(start + CHUNKSIZE, file.size);
      const chunk = file.slice(start, _chunkEnd);
      const data = async () => {
        await getUploadVideoData().then(({ location }) => {
          if (location == null) {
            setStatus("resumable");
            throw new Error(
              "アップロードに失敗しました。再度ログインしてからお試しください。"
            );
          } else {
            chunkUpload(
              chunk,
              file.size,
              CHUNKSIZE,
              location,
              start,
              _chunkEnd
            ).then((response) => {
              if (response.status === 308) {
                const range = response.headers.get("range");
                const _end = Number(range?.split("-")[1]);
                updateProgress((_end / file.size) * 100);
                setStart(_end + 1);
              } else if (response.status >= 500 && response.status < 600) {
                //setResendErrorChunk((prev) => !prev);
                // TODO この際にどのように対応するか？
                // パターンとしてはもう一度同じものを送り直すか、止まってることを示す。
              } else if (response.status === 200 || 201) {
                clearUploadVideoData();
                setStatus("success");
                //readyToEncode(roomId, videoId);
              }
            });
          }
        });
      };
      data();
    }
    // 中断の際
  }, [
    status,
    start,
    file,
    CHUNKSIZE,
    mutate,
    getUploadVideoData,
    clearUploadVideoData,
    roomId,
    videoId,
  ]);

  const uploadMutate = async (
    roomId: string,
    file: File,
    payload: CreateVideoRequestPayload
  ) => {
    return mutate<void>(
      currentUser?.id
        ? `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`
        : null,
      async () => {
        if (idToken == null || !file) {
          throw new Error(
            "アップロードに失敗しました。再度ログインしてからお試しください。"
          );
        }
        await getUploadVideoData().then(async ({ location }) => {
          if (location !== null) {
            const response = await checkResumableUpload(location, file.size);
            if (response.status === 308) {
              setStatus("uploading");
              const range = response.headers.get("range");
              if (range) {
                const _end = Number(range?.split("-")[1]);
                updateProgress((_end / file.size) * 100);
                setStart(_end + 1);
              }
            }
          } else {
            setStatus("uploading");
            const video = await createRoomVideo(payload.title, idToken, roomId);
            const extension = file.name.split(".").pop();
            setVideoId(video.videoId);
            const uploadUrl = await getUploadUrl(
              video.videoId,
              idToken,
              extension
            );
            const _location = await startChunkUpload(uploadUrl);
            if (_location == null) {
              throw new Error(
                "アップロードに失敗しました。再度ログインしてからお試しください。"
              );
            }
            await saveUploadVideoData(_location, file.name);
          }
          setFile(file);
          mutate([
            `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`,
            currentUser?.id,
          ]);
        });
      }
    );
  };

  const onResendResumableUploadHandler = async () => {
    if (idToken == null || !file) {
      throw new Error(
        "アップロードに失敗しました。再度ログインしてからお試しください。"
      );
    }
    await getUploadVideoData().then(async ({ location }) => {
      if (location !== null) {
        const response = await checkResumableUpload(location, file.size);
        if (response.status === 308) {
          setStatus("uploading");
          const range = response.headers.get("range");
          if (range) {
            const _end = Number(range?.split("-")[1]);
            updateProgress((_end / file.size) * 100);
            setStart(_end + 1);
          }
        }
      }
    });
  };

  const cancelResumableUpload = async () => {
    await clearUploadVideoData();
    /**
     * TODO : cancelResumableUploadすると204で以降全ての処理がうまくいかなくなるので未実装。
     * 本来はurlが一週間残り続けるのが嫌なので、キャンセル等された際にはそのurlは破棄する必要がある。
     * async (location: string) => {
     *  return await fetch(location, {
     *  method: "DELETE",
     *  headers: {
     *    "Content-Length": "0",
     *   },
     *  });
     * };
     */
  };

  return {
    uploadStatus: status,
    persentage,
    uploadMutate,
    getUploadVideoData,
    cancelResumableUpload,
    onResendResumableUploadHandler,
    setStatus,
  };
};

// Room内のVideo操作
export const useRoomVideo = (offset: number, perPage: number) => {
  const { mutate } = useSWRConfig();
  const { currentUser, idToken } = useAuthState();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState({
    videoId: "",
  });
  const toast = useToast();

  /**
   * 動画のエンコード状態から閲覧可能状態に切り替えるための関数
   * (注意) FetchRoomVideosResponse がroomVideosの取得の返却値の型
   *
   * @param roomId: string
   * @param videoId: string
   * @param thumbnail: string
   */
  const readyToWatch = (roomId: string, videoId: string) => {
    mutate<FetchRoomVideosResponse>(
      [
        `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`,
        currentUser?.id,
      ],
      async (roomVideosResponse: FetchRoomVideosResponse | undefined) => {
        return {
          ...roomVideosResponse,
          videos: roomVideosResponse?.videos.map((video) => {
            if (video.videoId !== videoId) {
              return video;
            }
            return { ...video, status: "active" };
          }),
        } as FetchRoomVideosResponse;
      }
    );
  };

  /**
   * type FetchRoomVideosResponse = {
  videos: Video[];
  paging: {
    totalCount: number;
    limit: number;
    offset: number;
  };
  users: VideoUser[];
};
   */

  /**
   * 動画投稿のステータスから動画のエンコード状態に移行するための関数
   * 当該の動画のstatusを encodeNotready or encodingにするのが良さそう
   */
  const readyToEncode = (roomId: string, videoId: string) => {
    mutate<FetchRoomVideosResponse>(
      [
        `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`,
        currentUser?.id,
      ],
      async (roomVideosResponse: FetchRoomVideosResponse | undefined) => {
        return {
          ...roomVideosResponse,
          videoTranscodeProgress: {
            progress: 0,
            stage: "",
            status: "encoding",
          },
          videos: roomVideosResponse?.videos.map((video) => {
            if (video.videoId !== videoId) {
              return video;
            }
            return {
              ...video,
              status: "notActive",
              progress: {
                progress: 0,
                stage: "",
                status: "encoding",
              },
            };
          }),
        } as FetchRoomVideosResponse;
      }
    );
  };

  const updateRoomVideoMutate = async (
    roomId: Room["roomId"],
    payload: updateVideoPayload,
    videoId: Video["videoId"]
  ): Promise<Video | undefined> => {
    return mutate<Video | undefined>(
      currentUser?.id
        ? `https://${apiHost}/aiseki/api/videos/${videoId}`
        : null,
      async () => {
        if (idToken === null) return;
        setIsUpdating(true);
        const response = await updateVideo(videoId, payload, idToken);
        setIsUpdating(false);
        mutate([
          `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`,
          currentUser?.id,
        ]);
        return response;
      }
    );
  };

  /**
   *
   * @param videoId
   * @param roomId
   * @returns
   */
  const deleteRoomVideoMutate = async (
    videoId: Video["videoId"],
    roomId: Room["roomId"]
  ) => {
    return mutate<void>(
      currentUser?.id
        ? `https://${apiHost}/aiseki/api/videos/${videoId}`
        : null,
      async () => {
        try {
          if (idToken === null) return;
          setIsDeleting({
            videoId: videoId,
          });
          await deleteVideo(videoId, idToken);
          /** mutateをawaitすることでupdate後のuserが取得でき、paginationのところのkeyとなる */
          await mutate([
            `https://${apiHost}/aiseki/api/rooms/${roomId}/videos?limit=${perPage}&offset=${offset}`,
            currentUser?.id,
          ]);
        } catch (e) {
          toast({
            title: "動画の削除に失敗しました",
            status: "error",
            position: "top",
            isClosable: true,
          });
        } finally {
          setIsDeleting({ videoId: "" });
        }
      }
    );
  };
  return {
    updateRoomVideoMutate: updateRoomVideoMutate,
    isUpdating: isUpdating,
    deleteRoomVideoMutate,
    isDeleting: isDeleting,
    readyToWatch,
    readyToEncode,
  };
};

export const SHARED_LINK_EXPIRATION_OPTIONS = [3, 7, 30, -1];

// オーガニゼーション共有リンク取得API

export type RoomSharedLink = {
  invitationCode: string;
  availableCount: number;
  expiredAt: number;
  createdAt: number;
};

type FetchSharedLinkResponse = {
  invitation: RoomSharedLink;
};

export const useFetchSharedLink = (roomId: string | undefined) => {
  const { currentUser, idToken } = useAuthState();
  const cacheKey =
    roomId && currentUser?.id
      ? `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation`
      : null;

  const { data, error } = useSWR(cacheKey, async (url) => {
    const { data } = await axios.get<FetchSharedLinkResponse>(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return data.invitation;
  });

  return {
    sharedLink: data,
    isLoading: !data && !error,
    error,
  };
};

type SharedLinkResponse = {
  result: RoomSharedLink;
};

export type SharedLinkPayload = {
  payload: { expiredAt: number };
};

// 公開リンク作成API

export const useCreateSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isCreating, setIsCreating] = useState(false);

  const createSharedLink = (
    roomId: Room["roomId"] | undefined,
    payload: SharedLinkPayload
  ) => {
    const cacheKey =
      roomId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation`
        : null;
    return mutate<RoomSharedLink>(cacheKey, async () => {
      if (!roomId) {
        return undefined;
      }
      setIsCreating(true);
      const { data } = await axios.post<SharedLinkResponse>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation`,
        payload,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsCreating(false);
      return data.result;
    });
  };

  return {
    mutate: createSharedLink,
    isCreating,
  };
};

// 公開リンク更新API

export const useUpdateSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isUpdating, setIsUpdating] = useState(false);

  const updateSharedLink = (
    roomId: Room["roomId"] | undefined,
    payload: SharedLinkPayload
  ) => {
    const cacheKey =
      roomId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation`
        : null;
    return mutate<RoomSharedLink>(cacheKey, async () => {
      if (!roomId) {
        return undefined;
      }
      setIsUpdating(true);
      const { data } = await axios.put<SharedLinkResponse>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation`,
        payload,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsUpdating(false);
      return data.result;
    });
  };

  return {
    mutate: updateSharedLink,
    isUpdating,
  };
};

// 公開リンク削除API

export const useDeleteSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteSharedLink = (roomId: Room["roomId"] | undefined) => {
    const cacheKey =
      roomId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation`
        : null;
    return mutate<RoomSharedLink | undefined>(cacheKey, async () => {
      if (!roomId) {
        return undefined;
      }
      setIsDeleting(true);
      await axios.delete<SharedLinkResponse>(
        `https://${apiHost}/aiseki/api/rooms/${roomId}/sharedInvitation`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsDeleting(false);
      return undefined;
    });
  };

  return {
    mutate: deleteSharedLink,
    isDeleting,
  };
};
