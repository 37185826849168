import React, { useEffect, useRef } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ListItem,
  SpaceProps,
  UnorderedList,
  Text,
} from "@chakra-ui/react";
import EditableTagList from "components/editable-tag-list";

type Props = {
  form: UseFormReturn<BaseFieldValue>;
  placeholder?: string;
  label: string;
} & SpaceProps;

type BaseFieldValue = {
  emailList: { value: string }[];
};

const EMAIL_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const InviteEmailListInput = (props: React.PropsWithoutRef<Props>) => {
  const { form, label, placeholder, ...spaceProps } = props;
  const { t } = useTranslation();
  const {
    control,
    register,
    getValues,
    trigger,
    formState: { errors },
  } = form;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "emailList",
  });

  const emailList = useWatch({ control, name: "emailList" }).map(
    (v) => v.value
  );
  const emailRef = useRef<HTMLInputElement>(null);

  // react-hook-formではonBlur, onChange, onSubmitをトリガーに
  // バリデーションが走るが、type=hiddenでは前者2つが発火しない
  // そのため、項目が増えるたび明示的にバリデーションをtriggerしている
  // @see https://react-hook-form.com/api/usefieldarray
  useEffect(() => {
    trigger("emailList");
  }, [fields, trigger]);

  // apply multi input email
  const separateEmailsAndAppend = (email: string) => {
    const emails = email.split(",");
    emails.forEach((e) => {
      if (e.length) {
        append({ value: e });
      }
    });
  };

  return (
    <FormControl id="email-list" isInvalid={!!errors.emailList} {...spaceProps}>
      <FormLabel fontWeight={"bold"}>{label}</FormLabel>
      <Text>{t("components:inputEmailDescription")}</Text>
      {fields.map((item, index) => (
        <Input
          key={item.id}
          type="hidden"
          {...register(`emailList.${index}.value` as const, {
            pattern: EMAIL_REGEXP,
            validate: {
              unique: (v) => {
                return (
                  getValues("emailList").filter((e) => e.value === v).length ===
                  1
                );
              },
            },
          })}
        />
      ))}
      <EditableTagList
        tags={emailList}
        isInvalid={!!errors.emailList}
        disableEnterSubmit={true}
        inputProps={{
          ref: emailRef,
          type: "email",
          borderColor: "transparent",
          placeholder: placeholder || t("components:inputEmail"),
        }}
        onAddTag={(email) => {
          if (emailRef?.current?.value?.length) {
            separateEmailsAndAppend(email);
            emailRef.current.value = "";
          }
        }}
        onRemoveTag={(email, i) => {
          remove(i);
        }}
      />
      <FormErrorMessage ml={2}>
        <UnorderedList>
          {errors.emailList?.some((e) => e?.value?.type === "unique") && (
            <ListItem>{t("components:duplicateEmailError")}</ListItem>
          )}
          {errors.emailList?.some((e) => e?.value?.type === "pattern") && (
            <ListItem>{t("components:invalidEmailError")}</ListItem>
          )}
        </UnorderedList>
      </FormErrorMessage>
    </FormControl>
  );
};

export default InviteEmailListInput;
