import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import {
  Flex,
  Center,
  Box,
  Icon,
  IconButton,
  Collapse,
  Divider,
  VStack,
  HStack,
  Text,
  Image,
} from "@chakra-ui/react";
import { CgOrganisation } from "react-icons/cg";
import { MdHistory, MdRoom, MdMenu, MdVideoLibrary } from "react-icons/md";
import { LayoutProps } from "../index";
import OrganiztionListContainer from "./OrganizationList";
import OrganizationName from "./OrganizationName";
import RoomColumn from "./RoomColumn";
import RoomList from "./RoomList";

export const Sidebar: React.FC<LayoutProps> = (props) => {
  const {
    isCollapsed,
    whichIsActive,
    currentRoom,
    rooms,
    isLoadingRooms,
    isLoadingInvitations,
    onOpenCreateModal,
    onOpenSettingModal,
  } = props;
  const [isOpen, setIsOpen] = useState(!isCollapsed);
  const { t } = useTranslation();

  const optionPaths = [
    {
      title: t("common:library"),
      key: "library",
      href: "/studio",
      icon: MdVideoLibrary,
    },
    {
      title: t("common:viewingHistory"),
      key: "history",
      href: "/studio/viewing",
      icon: MdHistory,
    },
  ] as const;

  return (
    <Box
      bgColor="aisekiNavy.500"
      color="aisekiWhite.500"
      w={isOpen ? 80 : 14}
      h="100vh"
      transition="400ms"
    >
      <Collapse in={!isOpen} animateOpacity>
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          spacing={1}
          h={"100vh"}
          w={"full"}
        >
          <Box>
            <Center
              _hover={{
                bgColor: "aisekiNavy.400",
                color: "aisekiWhite.500",
              }}
              transition="200ms"
              cursor="pointer"
              w="full"
              p={3}
              h={16}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <Icon as={MdMenu} w={8} h={8} aria-label="メニューを閉じる" />
            </Center>
            <Center
              bgColor={
                whichIsActive === "organization"
                  ? "aisekiYellow.500"
                  : "aisekiNavy.500"
              }
              color={
                whichIsActive === "organization"
                  ? "aisekiBlack.500"
                  : "aisekiWhite.500"
              }
              transition="200ms"
              key={"organization"}
              w="full"
              p={3}
            >
              <Icon as={CgOrganisation} w={6} h={6} aria-label="ルーム" />
            </Center>
            <Center
              bgColor={
                whichIsActive === "room" ? "aisekiYellow.500" : "aisekiNavy.500"
              }
              color={
                whichIsActive === "room" ? "aisekiBlack.500" : "aisekiWhite.500"
              }
              transition="200ms"
              key={"room"}
              w="full"
              p={3}
            >
              <Icon as={MdRoom} w={6} h={6} aria-label="ルーム" />
            </Center>
          </Box>

          <Box>
            {optionPaths.map((path) => {
              const isActive = path.key === whichIsActive;
              return (
                <Link href={path.href} passHref key={path.key}>
                  <Box
                    as={"a"}
                    display="flex"
                    justifyContent="center"
                    fontSize="sm"
                    bgColor={isActive ? "aisekiYellow.500" : "aisekiNavy.500"}
                    color={isActive ? "aisekiBlack.500" : "aisekiWhite.500"}
                    _hover={{
                      bgColor: isActive ? "aisekiYellow.400" : "aisekiNavy.400",
                      color: isActive ? "aisekiBlack.500" : "aisekiWhite.500",
                    }}
                    transition="200ms"
                    cursor="pointer"
                    key={path.key}
                    w="full"
                    p={3}
                  >
                    <Icon as={path.icon} w={6} h={6} />
                  </Box>
                </Link>
              );
            })}
          </Box>
        </Flex>
      </Collapse>

      <Collapse in={isOpen} animateOpacity>
        <Flex direction={"column"} w={"full"}>
          <Flex
            alignItems={"center"}
            borderBottom={"1px"}
            px={2}
            h={14}
            w={80}
            position="sticky"
            borderColor="rgba(255, 255, 255, 0.2)"
            zIndex={999}
            backgroundColor="aisekiNavy.500"
          >
            <IconButton
              colorScheme="aisekiNavy.500"
              icon={<Icon as={MdMenu} w={6} h={6} />}
              aria-label="メニューを閉じる"
              onClick={() => setIsOpen((prev) => !prev)}
              mr={4}
            />
            <Image src={"/logo.png"} alt="logo" h={4} />
          </Flex>
          <Flex direction={"row"}>
            <Flex
              w={16}
              py={2}
              pr={"4px"}
              pl={"2px"}
              borderRight={"1px"}
              borderColor="rgba(255, 255, 255, 0.2)"
              overflowY="auto"
              h="calc(100vh - 64px)"
            >
              <OrganiztionListContainer />
            </Flex>
            <Flex direction="column">
              <Flex direction="column" position="sticky" h={24} zIndex={999}>
                <OrganizationName whichIsActive={whichIsActive} />
                <RoomColumn
                  whichIsActive={whichIsActive}
                  onOpenCreateModal={onOpenCreateModal}
                />
                <Divider />
              </Flex>
              <Flex
                direction="column"
                justifyContent={"space-between"}
                w={64}
                h="calc(100vh - 260px)"
              >
                <Flex direction="column" overflowY="auto">
                  {rooms
                    ?.sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      if (a.roomId > b.roomId) return 1;
                      if (a.roomId < b.roomId) return -1;
                      return 0;
                    })
                    .map((room) => {
                      const isActive = currentRoom
                        ? room.roomId === currentRoom.roomId
                        : false;
                      return (
                        <RoomList
                          onOpenSettingModal={onOpenSettingModal}
                          room={room}
                          isActive={isActive}
                          key={room.roomId}
                        />
                      );
                    })}
                </Flex>
              </Flex>

              <VStack>
                {optionPaths.map((path) => {
                  const isActive = path.key === whichIsActive;
                  return (
                    <Link href={path.href} passHref key={path.key}>
                      <Box
                        as={"a"}
                        fontSize="sm"
                        width="100%"
                        bgColor={
                          isActive ? "aisekiYellow.500" : "aisekiNavy.500"
                        }
                        color={isActive ? "aisekiBlack.500" : "aisekiWhite.500"}
                        _hover={{
                          bgColor: isActive
                            ? "aisekiYellow.400"
                            : "aisekiNavy.400",
                          color: isActive
                            ? "aisekiBlack.500"
                            : "aisekiWhite.500",
                        }}
                        transition="200ms"
                        cursor="pointer"
                        key={path.key}
                        p={3}
                      >
                        <HStack justifyContent={"space-between"}>
                          <Center justifyContent={"left"}>
                            <Icon as={path.icon} w={5} h={5} />
                            <Text ml={4}>{path.title}</Text>
                          </Center>
                        </HStack>
                      </Box>
                    </Link>
                  );
                })}
              </VStack>
            </Flex>
          </Flex>
        </Flex>
      </Collapse>
    </Box>
  );
};
