import { useRouter } from "next/router";
import { Flex, Icon, Text, Center, Box } from "@chakra-ui/react";
import { CgOrganisation } from "react-icons/cg";
import { MdSettings } from "react-icons/md";
import { LayoutProps } from "components/layout";
import { useOrganizationContext } from "lib/organization/organization-context";

type OrganizationNameProps = Pick<LayoutProps, "whichIsActive">;

const OrganizationName = ({ whichIsActive }: OrganizationNameProps) => {
  const { currentOrganization } = useOrganizationContext();
  const router = useRouter();
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      h={12}
      p={2}
      _hover={{
        bgColor: "aisekiNavy.400",
        color: "aisekiWhite.500",
      }}
      bgColor={
        whichIsActive === "organization" ? "aisekiYellow.500" : "aisekiNavy.500"
      }
      color={
        whichIsActive === "organization" ? "aisekiBlack.500" : "aisekiWhite.500"
      }
      onClick={(e) => {
        router.push(`/organizations/${currentOrganization?.id}`);
        e.preventDefault();
      }}
      cursor={"pointer"}
      w={64}
    >
      <Center>
        <Icon as={CgOrganisation} w={5} h={5} />
        <Box w={180}>
          <Text ml={4} isTruncated truncate={2} overflow="hidden">
            {currentOrganization?.name}
          </Text>
        </Box>
      </Center>
      <Icon as={MdSettings} w={5} h={5} />
    </Flex>
  );
};
export default OrganizationName;
