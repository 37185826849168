import React from "react";
import { useTranslation } from "react-i18next";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Skeleton,
} from "@chakra-ui/react";
import { useToast } from "hooks/use-toast";
import { Member, Room } from "./type";

type Props = {
  room: Room | undefined;
  member: Member | undefined;
  onDelete: () => Promise<Member[] | undefined>;
  onComplete?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

const MemberDeleteDialog: React.FC<Props> = (props) => {
  const { room, member, onDelete, isOpen, onClose, onComplete } = props;
  const isDataLoading = !room || !member;

  const { successToast, errorToast } = useToast();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { t } = useTranslation("rooms");

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("removeMember")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Skeleton isLoaded={!isDataLoading}></Skeleton>
            {t("removeMemberText1", {
              user: member?.displayName,
              room: room?.name,
            })}
            {t("removeMemberText2")}
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack>
              <Button isLoading={isDeleting} onClick={onClose}>
                {t("cancel")}
              </Button>
              <Button
                colorScheme="red"
                isDisabled={isDataLoading}
                isLoading={isDeleting}
                onClick={async () => {
                  setIsDeleting(true);
                  try {
                    await onDelete();
                    successToast({
                      title: t("successRemoveUserToast", {
                        user: member?.displayName,
                        room: room?.name,
                      }),
                    });
                  } catch (e) {
                    errorToast({
                      title: t("failToRemoveUserToast"),
                    });
                  }
                  setIsDeleting(false);
                  onClose();
                  onComplete && onComplete();
                }}
              >
                {t("remove")}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default MemberDeleteDialog;
