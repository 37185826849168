import { useRouter } from "next/router";
import {
  Avatar,
  VStack,
  Text,
  Divider,
  useDisclosure,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import TooltipButton from "components/button/tooltip-button";
import { Organization, useFetchOrganizations } from "lib/organization/api";
import { useOrganizationContext } from "lib/organization/organization-context";
import { OrganizationCreateModalContainer } from "lib/organization/organization-create-modal";

type OrganizationListProps = {
  currentOrganization: Organization;
  organizations: Organization[];
  onSwitchOrganization: (organizationId: string) => void | Promise<void>;
};

/**
 * 所属しているオーガニゼーションのアイコンをリスト的に表示する
 *
 * TODO:
 * 作成部分はした？などに固定して表示する
 * それ以外のList部分がスクロールするようにしたい
 * @param currentOrganization
 * @param organizations
 * @param onSwitchOrganization
 * @returns
 */
const OrganizationList = ({
  currentOrganization,
  organizations,
  onSwitchOrganization,
}: OrganizationListProps) => {
  const router = useRouter();
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <VStack spacing={1} h={"full"}>
      {organizations.map((organization: Organization) => (
        <Box
          key={organization.id}
          borderLeft={currentOrganization.id === organization.id ? "4px" : 0}
          borderColor="aisekiYellow.500"
          p={1}
          pl={currentOrganization.id === organization.id ? 0 : "4px"}
          _hover={{
            bgColor: "aisekiNavy.400",
            color: "aisekiWhite.500",
          }}
        >
          <Tooltip label={organization.name}>
            <Avatar
              onClick={() => {
                onSwitchOrganization(organization.id);
                router.push(`/organizations/${organization.id}`);
              }}
              src={organization.thumbnailUrl}
              boxSize={12}
              name={organization.name}
              cursor="pointer"
            />
          </Tooltip>
        </Box>
      ))}
      <Divider colorScheme="white" />
      <Box h={12} p={1}>
        <TooltipButton
          onClick={onOpen}
          label="オーガニゼーションの作成"
          aria-label={"create-organization"}
        >
          <Text color={"black"}>+</Text>
        </TooltipButton>
      </Box>
      {isOpen && (
        <OrganizationCreateModalContainer
          isOpen={isOpen}
          onRequestClose={onClose}
        />
      )}
    </VStack>
  );
};

const OrganiztionListContainer = () => {
  const { organizations } = useFetchOrganizations();
  const { currentOrganization, handleSwitchOrganization } =
    useOrganizationContext();

  if (!organizations || !currentOrganization) {
    return null;
  }

  return (
    <OrganizationList
      currentOrganization={currentOrganization}
      organizations={organizations}
      onSwitchOrganization={handleSwitchOrganization}
    />
  );
};

export default OrganiztionListContainer;
