import { useTranslation } from "react-i18next";
import { Select } from "@chakra-ui/react";

type Language = "ja" | "en";

export function LanguageSelector() {
  const { t, i18n } = useTranslation("common");

  return (
    <Select
      placeholder={t("selectLanguage")}
      onChange={(ev) => i18n.changeLanguage(ev.target.value as Language)}
      fontSize="sm"
      width="10rem"
    >
      <option value="ja">{t("ja")}</option>
      <option value="en">{t("en")}</option>
    </Select>
  );
}
