import { RefObject } from "react";
import {
  Box,
  Input,
  InputProps,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  BoxProps,
} from "@chakra-ui/react";

type Props = {
  tags: string[];
  isInvalid?: boolean;
  inputProps: InputProps & {
    ref: RefObject<HTMLInputElement>;
  };
  disableEnterSubmit?: boolean;
  onAddTag: (tag: string, index: number) => void;
  onRemoveTag: (tag: string, index: number) => void;
};

const EditableTagList: React.FC<Props & BoxProps> = (props) => {
  const {
    tags,
    inputProps,
    isInvalid,
    disableEnterSubmit,
    onAddTag,
    onRemoveTag,
    ...rest
  } = props;

  return (
    <Box
      borderColor={isInvalid ? "red.500" : ""}
      borderWidth={isInvalid ? "2px" : "1px"}
      borderRadius="md"
      p="2"
      {...rest}
    >
      <Wrap spacing={2}>
        {tags.map((tag, index) => (
          <WrapItem key={tag + index}>
            <Tag borderRadius="full" variant="solid" colorScheme="blue">
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => onRemoveTag(tag, index)} />
            </Tag>
          </WrapItem>
        ))}
        <WrapItem flexGrow={1}>
          <Input
            variant="unstyled"
            onBlur={(e) => {
              onAddTag(e.target.value, tags.length - 1);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onAddTag(inputProps.ref.current?.value ?? "", tags.length - 1);
                disableEnterSubmit && e.preventDefault();
              }
            }}
            w="full"
            p={0}
            {...inputProps}
          />
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default EditableTagList;
