interface OneColumnLayoutProps {
  children: React.ReactNode;
  containerClass?: string;
}
export function OneColumnLayout({
  children,
  containerClass,
}: OneColumnLayoutProps) {
  return (
    <>
      <header className="flex items-center container mx-auto py-4 mb-12">
        <h1 className="text-lg font-bold">tsucom</h1>
      </header>
      <section className="w-6/12 mx-auto rounded border border-gray-200 p-16">
        <div className={containerClass}>{children}</div>
      </section>
    </>
  );
}
