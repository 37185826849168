import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Flex, Text, IconButton, Select, FlexProps } from "@chakra-ui/react";

type Props = {
  totalCount: number;
  currentPage: number;
  perPage: number;
  onChangePerPage: (perPage: number) => void;
  onClickPageLink: (page: number) => void;
  perPagePatternList?: number[];
} & FlexProps;

// 表示件数のパターン
const DEFAULT_PER_PAGE_PATTERN_LIST = [10, 20, 30, 50, 100];

const Pagination = (props: Props) => {
  const {
    totalCount,
    currentPage: originalCurrentPage,
    perPage,
    onChangePerPage,
    onClickPageLink,
    perPagePatternList = DEFAULT_PER_PAGE_PATTERN_LIST,
    ...rest
  } = props;

  // ページングに関する計算
  const totalPage = Math.max(Math.ceil(totalCount / perPage), 1);
  const currentPage = Math.min(originalCurrentPage, totalPage);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPage;

  return (
    <Flex justifyContent="space-between" m={4} alignItems="center" {...rest}>
      <Flex>
        <IconButton
          onClick={() => onClickPageLink(1)}
          isDisabled={isFirstPage}
          icon={<ArrowLeftIcon h={3} w={3} />}
          aria-label="最初のページに戻る"
          mr={4}
        />
        <IconButton
          onClick={() => onClickPageLink(currentPage - 1)}
          isDisabled={isFirstPage}
          icon={<ChevronLeftIcon h={6} w={6} />}
          aria-label="前のページに戻る"
        />
      </Flex>

      <Flex alignItems="center">
        <Text flexShrink={0} mr={8}>
          Page
          <Text fontWeight="bold" as="span" mr={1} ml={1}>
            {currentPage}
          </Text>
          of
          <Text fontWeight="bold" as="span" mr={1} ml={1}>
            {totalPage}
          </Text>
        </Text>
        <Text flexShrink={0} mr={2}>
          Per page:
        </Text>
        <Select
          w={24}
          value={perPage}
          onChange={(e) => {
            onChangePerPage(Number(e.target.value) || 10);
          }}
        >
          {perPagePatternList.map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <IconButton
          onClick={() => onClickPageLink(currentPage + 1)}
          isDisabled={isLastPage}
          icon={<ChevronRightIcon h={6} w={6} />}
          aria-label="次のページに進む"
        />
        <IconButton
          onClick={() => onClickPageLink(totalPage)}
          isDisabled={isLastPage}
          icon={<ArrowRightIcon h={3} w={3} />}
          aria-label="最後のページに進む"
          ml={4}
        />
      </Flex>
    </Flex>
  );
};

export default Pagination;
