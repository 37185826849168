import React, { MouseEvent } from "react";
import Link from "next/link";
import { Flex, Box, Icon, Text, IconButton } from "@chakra-ui/react";
import { BsHash } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import { LayoutProps } from "components/layout";
import { Room } from "lib/room/type";

type RoomColumnProps = Pick<LayoutProps, "onOpenSettingModal"> & {
  room: Room;
  isActive: boolean;
};

const RoomList = ({ room, isActive, onOpenSettingModal }: RoomColumnProps) => {
  return (
    <Link href={`/rooms/${room.roomId}`} passHref key={room.roomId}>
      <Box
        as="a"
        display="block"
        fontSize="sm"
        width="100%"
        bgColor={isActive ? "aisekiYellow.500" : "aisekiNavy.500"}
        color={isActive ? "aisekiBlack.500" : "aisekiWhite.500"}
        _hover={{
          bgColor: isActive ? "aisekiYellow.400" : "aisekiNavy.400",
          color: isActive ? "aisekiBlack.500" : "aisekiWhite.500",
        }}
        transition="200ms"
        cursor="pointer"
        p={0}
        pl={2}
      >
        <Flex alignItems="center" width="100%" height={10}>
          <Icon as={BsHash} mr={2} />
          <Text
            flexGrow={1}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {room.name}
          </Text>
          <IconButton
            icon={<Icon as={MdSettings} w={4} h={4} />}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              onOpenSettingModal(room.roomId);
              e.preventDefault();
            }}
            aria-label="ルームの設定をする"
            colorScheme="transparent"
            color={isActive ? "aisekiBlack.500" : "aisekiWhite.500"}
            size="sm"
            mr={1}
          />
        </Flex>
      </Box>
    </Link>
  );
};

export default RoomList;
