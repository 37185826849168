import { useState } from "react";
import { useRouter } from "next/router";
import { Flex, Box, useDisclosure } from "@chakra-ui/react";
import { useAuthState, User } from "@bonbon/data-access-auth";
import { useFetchInvitations, useFetchRooms } from "lib/room/api";
import { RoomCreateModalContainer } from "lib/room/room-create-modal";
import { RoomSettingModalContainer } from "lib/room/room-setting-modal";
import { Invitation, Room } from "lib/room/type";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
export { OneColumnLayout } from "./one-column";

/** 型だけ */
type paths = {
  title: string;
  key: string;
  href: string;
  icon: any;
}[];

export type LayoutProps = {
  title: string | React.ReactElement;
  children: React.ReactNode;
  user: User | null | undefined;
  currentRoom?: Room | null;
  rooms: Room[] | undefined;
  room?: Room;
  isLoadingRooms: boolean;
  invitations: Invitation[];
  isLoadingInvitations: boolean;
  isAuthenticated: boolean;
  isCollapsed?: boolean;
  onOpenSettingModal: (roomId: string) => void;
  onOpenCreateModal: () => void;
  whichIsActive?: paths[number]["key"];
  tags?: string[] | null;
  showSidebar?: boolean;
};

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children } = props;
  return (
    <Flex w="full" h="full" p={0}>
      {!props.showSidebar && <Sidebar {...props} />}
      <Flex
        w="full"
        h="100vh"
        p={0}
        flex={1}
        direction="column"
        overflowY={"auto"}
      >
        <Header {...props} />
        <Box w="full" p={0}>
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

type ContainerProps = Pick<
  LayoutProps,
  | "children"
  | "title"
  | "whichIsActive"
  | "isCollapsed"
  | "room"
  | "tags"
  | "showSidebar"
>;

const LayoutContainer: React.FC<ContainerProps> = (props) => {
  const {
    title,
    children,
    whichIsActive,
    isCollapsed,
    room,
    tags,
    showSidebar,
  } = props;

  const { currentUser, userLoading } = useAuthState();
  const { rooms, isLoading: isLoadingRooms } = useFetchRooms();
  const { invitations, isLoading: isLoadingInvitations } =
    useFetchInvitations();
  const router = useRouter();

  const roomId = room?.roomId || router.query.roomId;

  const {
    isOpen: isOpenSettingModal,
    onOpen: onOpenSettingModal,
    onClose: onCloseSettingModal,
  } = useDisclosure();
  const {
    isOpen: isOpenCreateModal,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  const [roomForSetting, setRoomForSetting] = useState<Room | undefined>(
    undefined
  );

  return (
    <Layout
      title={title}
      room={room}
      whichIsActive={whichIsActive}
      user={currentUser}
      isAuthenticated={!!currentUser && !userLoading}
      rooms={rooms}
      isLoadingRooms={isLoadingRooms}
      invitations={invitations}
      isLoadingInvitations={isLoadingInvitations}
      isCollapsed={isCollapsed}
      currentRoom={rooms?.find((room) => String(room.roomId) == roomId)}
      onOpenCreateModal={onOpenCreateModal}
      onOpenSettingModal={(roomId) => {
        setRoomForSetting(rooms?.find((room) => room.roomId == roomId));
        onOpenSettingModal();
      }}
      tags={tags}
      showSidebar={showSidebar}
    >
      <RoomCreateModalContainer
        isOpen={isOpenCreateModal}
        onRequestClose={onCloseCreateModal}
      />
      <RoomSettingModalContainer
        room={roomForSetting}
        isOpen={isOpenSettingModal}
        onRequestClose={() => {
          setRoomForSetting(undefined);
          onCloseSettingModal();
        }}
      />
      {children}
    </Layout>
  );
};

export default LayoutContainer;
