import React from "react";
import { useTranslation } from "react-i18next";
import router, { useRouter } from "next/router";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import { useDeleteRoom, useFetchRoom } from "./api";
import { Room } from "./type";

type Props = {
  room: Room | undefined;
  onDelete: () => Promise<void>;
  onComplete?: () => void;
  isOpen: boolean;
  onClose: () => void;
};

const RoomDeleteDialog: React.FC<Props> = (props) => {
  const { room, onDelete, isOpen, onClose, onComplete } = props;
  const isRoomLoading = !room;
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { t } = useTranslation("rooms");

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("deleteRoom")}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Skeleton isLoaded={!isRoomLoading}>
              {t("deleteRoomText", {
                room: room?.name,
              })}
            </Skeleton>
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack>
              <Button isLoading={isDeleting} onClick={onClose}>
                {t("cancel")}
              </Button>
              <Button
                colorScheme="red"
                isDisabled={isRoomLoading}
                isLoading={isDeleting}
                onClick={async () => {
                  setIsDeleting(true);
                  await onDelete();
                  setIsDeleting(false);
                  onClose();
                  onComplete && onComplete();
                }}
              >
                {t("delete")}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

type ContainerProps = Omit<Props, "room" | "onDelete"> & {
  roomId: Room["roomId"];
};

export const RoomDeleteDialogContainer: React.FC<ContainerProps> = (props) => {
  const { roomId, isOpen, onClose, onComplete } = props;

  const {
    query: { roomId: currentRoomId },
  } = useRouter();
  const { room } = useFetchRoom(roomId);
  const { deleteRoomMutate } = useDeleteRoom();
  const toast = useToast();

  const { t } = useTranslation("rooms");

  return (
    <RoomDeleteDialog
      room={room}
      onDelete={async () => {
        try {
          await deleteRoomMutate(room?.roomId || "");
          toast({
            title: t("successDeleteRoomToast", {
              room: room?.name,
            }),
            status: "success",
            position: "top",
            isClosable: true,
          });
        } catch (e) {
          toast({
            title: t("failToast"),
            status: "error",
            position: "top",
            isClosable: true,
          });
        }
      }}
      isOpen={isOpen}
      onClose={onClose}
      onComplete={() => {
        onComplete && onComplete();

        // 現在表示しているルームが削除された場合、ルームトップに遷移する
        if (room?.roomId === currentRoomId) {
          router.push("/rooms");
        }
      }}
    />
  );
};
