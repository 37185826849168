import React, { ChangeEvent, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CopyIcon, CheckIcon, DeleteIcon, LinkIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  HStack,
  useClipboard,
  IconButton,
  Text,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Collapse,
  Flex,
  InputLeftElement,
  Select,
  Spacer,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import InviteEmailListInput from "components/invite-email-list-input";
import { useToast } from "hooks/use-toast";
import { getExpireDate } from "lib/util/get-expire-date";
import {
  RoomSharedLink,
  SharedLinkPayload,
  SHARED_LINK_EXPIRATION_OPTIONS,
  useCreateRoom,
  useCreateSharedLink,
  useDeleteSharedLink,
  useFetchSharedLink,
  useUpdateSharedLink,
} from "./api";
import { MAX_ROOM_NAME_LENGTH } from "./const";
import { Room } from "./type";

type Props = {
  createdRoom: Room | null;
  sharedLink: RoomSharedLink | undefined;
  isOpen: boolean;
  isCreatingSharedLink: boolean;
  isUpdatingSharedLink: boolean;
  isDeletingSharedLink: boolean;
  onRequestClose: () => void;
  onCreateRoom: (
    roomName: string,
    emailList: string[]
  ) => Promise<Room[] | undefined>;
  onCreateSharedLink: (
    payload: SharedLinkPayload
  ) => Promise<RoomSharedLink | undefined>;
  onUpdateSharedLink: (
    payload: SharedLinkPayload
  ) => Promise<RoomSharedLink | undefined>;
  onDeleteSharedLink: () => Promise<RoomSharedLink | undefined>;
};

type FormSchema = {
  roomName: string;
  emailList: { value: string }[];
};

const RoomCreateModal: React.FC<Props> = (props) => {
  const {
    createdRoom,
    sharedLink,
    isOpen,
    isCreatingSharedLink,
    isDeletingSharedLink,
    isUpdatingSharedLink,
    onRequestClose,
    onCreateRoom,
    onCreateSharedLink,
    onDeleteSharedLink,
    onUpdateSharedLink,
  } = props;

  const invitationLink = `https://${process.env.NEXT_PUBLIC_APP_HOST}/rooms/${createdRoom?.roomId}/invitations/${sharedLink?.invitationCode}/link`;
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const { hasCopied, onCopy } = useClipboard(invitationLink);
  const { successToast, errorToast } = useToast();

  const schema = yup.object().shape({
    roomName: yup
      .string()
      .required(t("rooms:roomNameRequiredError"))
      .max(MAX_ROOM_NAME_LENGTH, t("rooms:roomNameMaxLengthError")),
  });

  const useFormReturn = useForm<FormSchema>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      roomName: "",
      emailList: [],
    },
  });
  const {
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors, isValid },
  } = useFormReturn;

  const handleCreateRoom = handleSubmit(async ({ roomName, emailList }) => {
    setIsCreating(true);
    try {
      await onCreateRoom(
        roomName,
        emailList.map((e) => e.value)
      );
      successToast({ title: t("rooms:successCreateRoomToast") });
    } catch (e) {
      errorToast({ title: t("rooms:failToCreateRoomToast") });
    }
    setIsCreating(false);
  });
  const handleCreateSharedLink = async () => {
    const expiredAt = getExpireDate(Math.floor(Date.now() / 1000), 3);
    try {
      await onCreateSharedLink({ payload: { expiredAt } });
      successToast({ title: t("rooms:successCreateRoomLinkToast") });
    } catch (e) {
      errorToast({ title: t("rooms:failToast") });
    }
  };
  const handleChangeExpireDate = async (e: ChangeEvent<HTMLSelectElement>) => {
    const days = Number(e.target.value);
    const expiredAt = getExpireDate(Math.floor(Date.now() / 1000), days);
    try {
      await onUpdateSharedLink({ payload: { expiredAt } });
      successToast({ title: t("rooms:successUpdateRoomLinkToast") });
    } catch (e) {
      errorToast({ title: t("rooms:failToast") });
    }
  };
  const handleDeleteSharedLink = async () => {
    try {
      await onDeleteSharedLink();
      successToast({ title: t("rooms:successDeleteRoomLinkToast") });
    } catch (e) {
      errorToast({ title: t("rooms:failToast") });
    }
  };
  const handleClose = () => {
    onRequestClose();
    reset({ roomName: "", emailList: [] });
  };

  const titleNameLength = watch().roomName.length || 0;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t("rooms:createRoom")}</ModalHeader>
        <Divider />
        <ModalBody>
          {!createdRoom ? (
            <form id="create_room" onSubmit={handleCreateRoom}>
              <FormControl id="room-name" isInvalid={!!errors.roomName} mb={3}>
                <FormLabel fontWeight={"bold"}>
                  <Flex className="justify">
                    {t("rooms:roomName")}
                    <Text as="span" color="red">
                      *
                    </Text>
                    <Spacer />
                    <Text
                      color={
                        titleNameLength > MAX_ROOM_NAME_LENGTH ? "red" : "black"
                      }
                    >
                      {titleNameLength} / {MAX_ROOM_NAME_LENGTH}文字
                    </Text>
                  </Flex>
                </FormLabel>
                <Input
                  id="roomName"
                  placeholder={t("rooms:pleaseInputRoomName")}
                  {...register("roomName" as const)}
                />
                <FormErrorMessage>{errors?.roomName?.message}</FormErrorMessage>
              </FormControl>
              <InviteEmailListInput
                /*
                  型 'UseFormReturn<FormSchema, any>' を型 'UseFormReturn<BaseFieldValue, any>'
                  に割り当てられずエラーになるためキャストしている。
                  以下の issue の議論を参考に、部分的なフォームを作成する方法を実践したが、フォームの型に
                  Arrayが含まれる場合はうまくいかない模様。
                  https://github.com/react-hook-form/react-hook-form/issues/6726#issuecomment-939467832
                */
                form={
                  useFormReturn as unknown as UseFormReturn<
                    Pick<FormSchema, "emailList">
                  >
                }
                label={t("rooms:inviteEmailAddress")}
              />
            </form>
          ) : (
            <>
              <Text p={2} mb={2}>
                {t("rooms:completeCreatingRoom", {
                  roomName: createdRoom.name,
                })}
              </Text>
              <Divider mb={4} />
              <FormControl id="room-name" mb={3}>
                <FormLabel fontWeight={"bold"}>
                  {t("rooms:inviteByPublicLink")}
                </FormLabel>
                <Alert status="warning">
                  <AlertIcon />
                  {t("rooms:publicInvitationAlart")}
                </Alert>
                <Collapse in={!sharedLink} animateOpacity>
                  <Flex>
                    <Spacer />
                    <Button
                      type="button"
                      onClick={handleCreateSharedLink}
                      isLoading={isCreatingSharedLink}
                      loadingText={t("rooms:generatingPublicLink")}
                      colorScheme="aisekiYellow"
                      color="black"
                      mt={2}
                    >
                      <LinkIcon mr={2} />
                      {t("rooms:generatePublicLink")}
                    </Button>
                  </Flex>
                </Collapse>

                <Collapse in={!!sharedLink} animateOpacity>
                  <InputGroup mt={2}>
                    <InputLeftElement>
                      <LinkIcon />
                    </InputLeftElement>
                    <Input value={invitationLink} isReadOnly />
                    <InputRightElement>
                      <IconButton
                        size="sm"
                        aria-label={hasCopied ? "Copied" : "Copy"}
                        colorScheme="aisekiYellow"
                        color="black"
                        icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
                        onClick={onCopy}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Flex mt={2}>
                    <Spacer />
                    <FormLabel
                      htmlFor="shared_link_expiration"
                      fontSize="sm"
                      mr={2}
                      mt={1}
                    >
                      {t("rooms:expired")}
                    </FormLabel>
                    <Select
                      id="shared_link_expiration"
                      onChange={handleChangeExpireDate}
                      disabled={isDeletingSharedLink}
                      loadingText={"有効期限を更新中"}
                      size="sm"
                      w="6rem"
                      mr={4}
                    >
                      {!!sharedLink &&
                        SHARED_LINK_EXPIRATION_OPTIONS.map((v) => {
                          const { createdAt, expiredAt } = sharedLink;
                          const days = Number(v);

                          return (
                            <option
                              key={`expiration_${v}`}
                              selected={
                                expiredAt == getExpireDate(createdAt, days)
                              }
                              value={v}
                            >
                              {v > 0
                                ? t("rooms:expiredDay", { amount: v })
                                : t("rooms:unlimited")}
                            </option>
                          );
                        })}
                    </Select>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={handleDeleteSharedLink}
                      disabled={isUpdatingSharedLink}
                      loadingText={t("rooms:deletingPublickLink")}
                      isLoading={isDeletingSharedLink}
                    >
                      <DeleteIcon mr={1} />
                      {t("rooms:deletePublicLink")}
                    </Button>
                  </Flex>
                </Collapse>
              </FormControl>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>{t("rooms:close")}</Button>
            <Button
              form="create_room"
              type="submit"
              isDisabled={!isValid}
              isLoading={isCreating}
              loadingText={t("rooms:creating")}
              hidden={!!createdRoom}
              colorScheme="aisekiYellow"
              color="aisekiBlack.500"
            >
              {t("rooms:create")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type ContainerProps = Pick<Props, "isOpen" | "onRequestClose">;

export const RoomCreateModalContainer: React.FC<ContainerProps> = (props) => {
  const { isOpen, onRequestClose } = props;

  const { createRoomMutate, createdRoom, cleanCreatedRoom } = useCreateRoom();
  const { sharedLink } = useFetchSharedLink(createdRoom?.roomId);
  const { mutate: createSharedLink, isCreating: isCreatingSharedLink } =
    useCreateSharedLink();
  const { mutate: updateSharedLink, isUpdating: isUpdatingSharedLink } =
    useUpdateSharedLink();
  const { mutate: deleteSharedLink, isDeleting: isDeletingSharedLink } =
    useDeleteSharedLink();

  return (
    <RoomCreateModal
      createdRoom={createdRoom}
      sharedLink={sharedLink}
      isOpen={isOpen}
      isCreatingSharedLink={isCreatingSharedLink}
      isDeletingSharedLink={isDeletingSharedLink}
      isUpdatingSharedLink={isUpdatingSharedLink}
      onRequestClose={() => {
        onRequestClose();
        cleanCreatedRoom();
      }}
      onCreateRoom={async (roomName, emailList) =>
        createRoomMutate({
          name: roomName,
          inviteUserEmailAddresses: emailList,
        })
      }
      onCreateSharedLink={(payload) =>
        createSharedLink(createdRoom?.roomId, payload)
      }
      onDeleteSharedLink={() => deleteSharedLink(createdRoom?.roomId)}
      onUpdateSharedLink={(payload) =>
        updateSharedLink(createdRoom?.roomId, payload)
      }
    />
  );
};
