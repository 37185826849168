import React, { useRef, useState } from "react";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import router from "next/router";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Text,
  FormErrorMessage,
  useMergeRefs,
  Avatar,
  Center,
  AvatarBadge,
  IconButton,
  Icon,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { MdCameraAlt } from "react-icons/md";
import * as yup from "yup";
import InviteEmailListInput from "components/invite-email-list-input";
import { useToast } from "hooks/use-toast";
import { useCreateOrganization, Organization } from "./api";
import { MAX_ORGANIZATIONS_NAME_LENGTH } from "./const";
import { useOrganizationContext } from "./organization-context";

type Props = {
  isOpen: boolean;
  isCreating: boolean;
  createdOrganization: Organization | null;
  onRequestClose: () => void;
  onCreateOrganization: (
    organizationName: string,
    logoFile: File,
    emailList: string[]
  ) => Promise<void>;
};

type FormSchema = {
  organizationName: string;
  logoFile: File[];
  emailList: { value: string }[];
};

const OrganizationCreateModal: React.FC<Props> = (props) => {
  const {
    isOpen,
    isCreating,
    createdOrganization,
    onRequestClose,
    onCreateOrganization,
  } = props;

  const { t } = useTranslation();

  const schema = yup.object().shape({
    organizationName: yup
      .string()
      .required(t("organizations:organizationNameRequiredError"))
      .max(
        MAX_ORGANIZATIONS_NAME_LENGTH,
        t("organizations:organizationNameMaxLengthError")
      ),
  });

  const useFormReturn = useForm<FormSchema>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      organizationName: "",
      emailList: [],
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useFormReturn;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRegister = register("logoFile" as const);
  const fileInputMergedRef = useMergeRefs(fileInputRef, fileInputRegister.ref);
  const logoFile = useWatch({ control, name: "logoFile" })?.[0];

  const titleNameLength = watch().organizationName.length || 0;

  const handleChangeLogo = () => {
    fileInputRef.current?.click();
  };

  const handleClose = () => {
    onRequestClose();
    reset({ organizationName: "", emailList: [] });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t("organizations:createOrganization")}</ModalHeader>
        <Divider />
        <ModalBody>
          {!createdOrganization ? (
            <form
              id="create_organization"
              onSubmit={handleSubmit(
                async ({ organizationName, logoFile, emailList }) => {
                  onCreateOrganization(
                    organizationName,
                    logoFile[0],
                    emailList.map((e) => e.value)
                  );
                }
              )}
            >
              <FormControl
                id="organization-logo"
                isInvalid={!!errors.logoFile}
                mb={3}
              >
                <FormLabel fontWeight={"bold"}>
                  {t("organizations:logo")}
                </FormLabel>
                <Input
                  id="organizationLogo"
                  type="file"
                  accept="image/*"
                  {...fileInputRegister}
                  ref={fileInputMergedRef}
                  hidden
                />
                <Center onClick={handleChangeLogo} cursor="pointer">
                  <Avatar
                    boxSize={28}
                    src={logoFile && URL.createObjectURL(logoFile)}
                    name={"No Image"}
                    title={t("organizations:logo")}
                    getInitials={(v: string) => v}
                  >
                    <AvatarBadge borderWidth={0}>
                      <IconButton
                        icon={<Icon as={MdCameraAlt} boxSize={6} />}
                        borderRadius="full"
                        colorScheme="aisekiYellow"
                        color="aisekiBlack.500"
                        aria-label="ロゴ画像をアップロードする"
                        mt={-4}
                        ml={-4}
                      />
                    </AvatarBadge>
                  </Avatar>
                </Center>
                <FormErrorMessage>
                  {errors?.organizationName?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="organization-name"
                isInvalid={!!errors.organizationName}
                mb={3}
              >
                <FormLabel fontWeight={"bold"}>
                  <Flex className="justify">
                    {t("organizations:organizationName")}
                    <Text as="span" color="red">
                      *
                    </Text>
                    <Spacer />
                    <Text
                      color={
                        titleNameLength > MAX_ORGANIZATIONS_NAME_LENGTH
                          ? "red"
                          : "black"
                      }
                    >
                      {titleNameLength} / {MAX_ORGANIZATIONS_NAME_LENGTH}文字
                    </Text>
                  </Flex>
                </FormLabel>
                <Input
                  id="organizationName"
                  placeholder={t("organizations:organizationNamePlacecholder")}
                  {...register("organizationName" as const)}
                />
                <FormErrorMessage>
                  {errors?.organizationName?.message}
                </FormErrorMessage>
              </FormControl>
              <InviteEmailListInput
                /*
                  型 'UseFormReturn<FormSchema, any>' を型 'UseFormReturn<BaseFieldValue, any>'
                  に割り当てられずエラーになるためキャストしている。
                  以下の issue の議論を参考に、部分的なフォームを作成する方法を実践したが、フォームの型に
                  Arrayが含まれる場合はうまくいかない模様。
                  https://github.com/react-hook-form/react-hook-form/issues/6726#issuecomment-939467832
                */
                form={
                  useFormReturn as unknown as UseFormReturn<
                    Pick<FormSchema, "emailList">
                  >
                }
                label={t("organizations:inviteByEmail")}
              />
            </form>
          ) : (
            <>
              <Text p={2} mb={2}>
                {t("organizations:createOrganizationCompleteText", {
                  organizationName: createdOrganization.name,
                })}
              </Text>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>{t("organizations:close")}</Button>
            <Button
              form="create_organization"
              type="submit"
              isDisabled={!isValid}
              isLoading={isCreating}
              loadingText={t("organizations:generating")}
              hidden={!!createdOrganization}
              colorScheme="aisekiYellow"
              color="aisekiBlack.500"
            >
              {t("organizations:create")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type ContainerProps = Pick<Props, "isOpen" | "onRequestClose">;

export const OrganizationCreateModalContainer: React.FC<ContainerProps> = (
  props
) => {
  const { isOpen, onRequestClose } = props;
  const { handleSwitchOrganization } = useOrganizationContext();
  const { mutate, createdOrganization, cleanCreatedOrganization } =
    useCreateOrganization();
  const [isCreating, setIsCreating] = useState(false);
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation("organizations");

  return (
    <OrganizationCreateModal
      isOpen={isOpen}
      isCreating={isCreating}
      createdOrganization={createdOrganization}
      onRequestClose={() => {
        onRequestClose();
        cleanCreatedOrganization();
      }}
      onCreateOrganization={async (organizationName, logoFile, emailList) => {
        setIsCreating(true);
        try {
          await mutate({
            name: organizationName,
            logoFile: logoFile,
            emailAddresses: emailList,
          });
          successToast({ title: t("completeCreateOriganizationModalText") });
          if (!!createdOrganization) {
            handleSwitchOrganization(createdOrganization.id);
            router.push(`/organizations/${createdOrganization.id}`);
          }
        } catch (e) {
          errorToast({
            title: t("failCreateOriganizationModalText"),
          });
        }
        setIsCreating(false);
      }}
    />
  );
};
