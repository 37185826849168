import { apiHost } from "lib/util/fetch";
import type { PublishStatus, Video } from "../type";

export async function createVideo(
  title: string,
  idToken: string
): Promise<Video> {
  const videoContainerCreateResponse = await fetch(
    `https://${apiHost}/aiseki/api/videos`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        payload: {
          bonbonUserId: "777777",
          title,
        },
      }),
    }
  );
  const videoContainer = await videoContainerCreateResponse.json();
  return videoContainer.video;
}

export const createRoomVideo = async (
  title: string,
  idToken: string,
  roomId: string
): Promise<Video> => {
  const videoContainerCreateResponse = await fetch(
    `https://${apiHost}/aiseki/api/rooms/${roomId}/videos`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        payload: {
          bonbonUserId: "777777",
          title,
        },
      }),
    }
  );
  const videoContainer = await videoContainerCreateResponse.json();
  return videoContainer.video;
};

/**
 * tsucomのバックエンドから動画のupload用のURLを取得する関数。
 *
 * @param {string} videoId
 * @param {string} idToken
 * @returns resumable uploadを始めるために必要な送信先url。
 *
 * (※) payloadにresumable: trueでresumable用のURLを取得できる。この場合このurlは最終的な送信先urlではない。
 */

export async function getUploadUrl(
  videoId: string,
  idToken: string,
  extension?: string
) {
  const videoUrlRes = await fetch(
    `https://${apiHost}/aiseki/api/videos/${videoId}/uploadUrl`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        payload: {
          // resumable情報を付与
          resumable: true,
          ext: extension,
          bonbonUserId: "777777",
          videoId,
        },
      }),
    }
  );
  return videoUrlRes.text();
}

/**
 * getUploadUrlで得たurlに対してファイル送信を開始することを指示する関数。
 * @param {string} url getUploadUrlで取得したresumableUplaod開始前のUrl
 * @returns {Promise<string|null>} resumableUploadを実際に実行可能なupload先Url
 *
 * 返却値のHeaderに付与されるLocation情報が実際にresumableuploadをする際の送信先になる。
 * このurlには認証などが不要でアップロード可能なので注意する。
 * (参考)https://cloud.google.com/storage/docs/performing-resumable-uploads
 */
export const startChunkUpload = async (url: string): Promise<string | null> => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Length": "0",
      "x-goog-resumable": "start",
      "Content-Type": "application/octet-stream",
    },
  });
  const uploadSessionUrl = await response.headers.get("Location");
  return uploadSessionUrl;
};

/**
 * resumableUploadでファイルをチャンクに分けて送信する関数。
 *
 * @param {Blob} chunk Fileを送る部分だけ切り取ったBlob
 * @param {number} fileSize 送信したいファイルの全体の長さ
 * @param {number} chunkSize このchunkUploadで実際に送信するデータのサイズ
 * @param {string} location resumableUpload先のurlでstartChunkUploadで変換した後のurl
 * @param {number} start fileのどの部分から送っているかの始まりの位置
 * @param {number} chunkEnd fileのどの部分までを送るかの終わりの位置
 * @returns {Response}
 * response このstatusによって以後のresumable uploadのstatusは変化する
 * - 308: resumbale uploadは完了していない => 続きから引き続き実行する必要がある
 * - 500~600: エラーが発生して終了した。
 * - 200 or 201: resumbale uploadが正常に終了した。
 *
 *
 * (*) file.slice(start, chunkEnd)できっているものを想定しているので
 *     Content-Rangeに渡す値は${start}-${chunkEnd-1}で送る
 */
export const chunkUpload = async (
  chunk: Blob,
  fileSize: number,
  chunkSize: number,
  location: string,
  start: number,
  chunkEnd: number
): Promise<Response> => {
  const response = await fetch(location, {
    method: "PUT",
    body: chunk,
    headers: {
      "Content-Type": "application/octet-stream",
      "Content-Length": `${chunkSize}`,
      "Content-Range": `bytes ${start}-${chunkEnd - 1}/${fileSize}`,
    },
  });
  return response;
};

/**
 * resumableUploadの既に送っている要素があるかを確認する関数。
 *
 * @param {string} location resumableUploadの送信先url
 * @param {number} fileSize 送信するファイルのサイズ
 * @returns
 *
 * 返却値によって解釈が異なる。
 * - 200: uploadが完了している。
 * - 308かつrangeあり: rangeから再開可能。
 * - 308かつrangeなし: 最初からuploadが必要。
 */
export const checkResumableUpload = async (
  location: string,
  fileSize: number
) => {
  return await fetch(location, {
    method: "PUT",
    headers: {
      "Content-Length": "0",
      "Content-Range": `bytes */${fileSize}`,
    },
  });
};

export async function updateVideo(
  videoId: string,
  payload: {
    title?: string;
    description?: string;
    publishStatus?: PublishStatus;
    tags?: string[];
  },
  idToken: string
): Promise<Video> {
  const response = await fetch(
    `https://${apiHost}/aiseki/api/videos/${videoId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ payload }),
    }
  );

  const videoData = (await response.json()) as Video;
  return videoData;
}

export async function deleteVideo(videoId: string, idToken: string) {
  const res = await fetch(`https://${apiHost}/aiseki/api/videos/${videoId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
  return res;
}
