import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import {
  Flex,
  Text,
  Heading,
  HStack,
  Spacer,
  Divider,
  Tag,
  TagLeftIcon,
  Tooltip,
  TagLabel,
} from "@chakra-ui/react";
import { MdOutlineRoom } from "react-icons/md";
import { LanguageSelector } from "components/language-selector";
import { LayoutProps } from "../index";
import { RoomNotificationPopoverContainer } from "./NotificationPopover";
import UserButton from "./UserButton";

/**
 *
 * @param props
 * @returns
 */

type HeaderProps = Pick<
  LayoutProps,
  "title" | "user" | "isAuthenticated" | "tags" | "room"
>;

export const Header: React.FC<HeaderProps> = (props) => {
  const { title, user, isAuthenticated, tags, room } = props;
  const router = useRouter();
  const { t } = useTranslation();
  const displayName =
    !!user && isAuthenticated ? user.display_name : "未ログイン";
  const onRoomBackHandler = () => {
    router.push(`/rooms/${room?.roomId}`);
  };

  return (
    <Flex
      bgColor="aisekiWhite.500"
      borderColor="aisekiGray.300"
      borderBottomWidth={1}
      width="100%"
      height="4rem"
      alignItems="center"
      position="sticky"
      zIndex={999}
      top={0}
      py={2}
      px={4}
    >
      <Heading
        size="md"
        overflow="scroll"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        display="flex"
        alignItems="center"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Flex>
          <Text pr={4}>{title}</Text>
          {room && (
            <Tooltip label={t("videos:backToRoom")}>
              <Tag onClick={onRoomBackHandler} cursor="pointer">
                <TagLeftIcon as={MdOutlineRoom} />
                <TagLabel>{room.name}</TagLabel>
              </Tag>
            </Tooltip>
          )}
          {tags?.map((tag) => (
            <Tag
              key={`tag-${tag}`}
              size="sm"
              variant="subtle"
              colorScheme="cyan"
              marginLeft={1}
            >
              <TagLabel>{tag}</TagLabel>
            </Tag>
          ))}
        </Flex>
      </Heading>
      <Spacer />
      <HStack mr={4}>
        <RoomNotificationPopoverContainer />
        <LanguageSelector />
      </HStack>
      <Divider orientation="vertical" ml={2} mr={2} />
      <UserButton
        displayName={displayName}
        userIconUrl={user?.image_url}
        userEmailAddress={user?.email}
      />
    </Flex>
  );
};
