import { useCallback } from "react";

/**
 * resumableUpload用にlocalStorageに保存するvideoDataを扱うCustomHooks
 * uploadVideoName: 過去に中断したものか判断するために利用
 * uploadVideoLocation: GCPのresumableUpload用に取得した送信先URL
 * stateもないしこのまとめかたする必要ないかも？
 */
export const useLocalStorageVideoData = () => {
  const LOCATION_KEY_NAME = "uploadVideoLocation";
  const FILENAME_KEY_NAME = "uploadVIdeoName";

  const saveUploadVideoData = useCallback(
    async (location: string, videoName: string) => {
      localStorage.setItem(LOCATION_KEY_NAME, location);
      localStorage.setItem(FILENAME_KEY_NAME, videoName);
    },
    []
  );

  const getUploadVideoData = useCallback(async () => {
    const location = localStorage.getItem(LOCATION_KEY_NAME);
    const videoName = localStorage.getItem(FILENAME_KEY_NAME);
    return { location, videoName };
  }, []);

  const clearUploadVideoData = useCallback(async () => {
    localStorage.removeItem(LOCATION_KEY_NAME);
    localStorage.removeItem(FILENAME_KEY_NAME);
  }, []);

  return {
    saveUploadVideoData,
    getUploadVideoData,
    clearUploadVideoData,
  };
};
