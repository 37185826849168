import React, { ChangeEvent, useState } from "react";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CopyIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  DeleteIcon,
  LinkIcon,
} from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Button,
  ButtonGroup,
  Box,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  TagLabel,
  HStack,
  VStack,
  Container,
  Flex,
  Spacer,
  Avatar,
  Text,
  useClipboard,
  IconButton,
  Editable,
  EditablePreview,
  EditableInput,
  useEditableControls,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Badge,
  useDisclosure,
  Alert,
  Collapse,
  AlertIcon,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";
import InviteEmailListInput from "components/invite-email-list-input";
import Pagination from "components/pagination";
import { useToast } from "hooks/use-toast";
import { Member, Room } from "lib/room/type";
import { getExpireDate } from "lib/util/get-expire-date";
import {
  RoomSharedLink,
  SharedLinkPayload,
  SHARED_LINK_EXPIRATION_OPTIONS,
  useCreateSharedLink,
  useDeleteRoomMember,
  useDeleteSharedLink,
  useFetchRoomMembers,
  useFetchSharedLink,
  useSendInvitation,
  useUpdateRoom,
  useUpdateSharedLink,
} from "./api";
import MemberDeleteDialog from "./member-delete-dialog";
import { RoomDeleteDialogContainer } from "./room-delete-dialog";

type Props = {
  sharedLink: RoomSharedLink | undefined;
  isRoomOwner: boolean;
  canUpdateRoomName: boolean;
  canRemoveMember: boolean;
  canInviteMember: boolean;
  canCreateSharedLink: boolean;
  isUpdatingRoom: boolean;
  isSendingInvitation: boolean;
  isCreatingSharedLink: boolean;
  isUpdatingSharedLink: boolean;
  isDeletingSharedLink: boolean;
  canRemoveSharedLink: boolean;
  room: Room;
  isOpen: boolean;
  onRequestClose: () => void;
  onUpdateRoom: (roomName: string) => Promise<Room[] | undefined>;
  onInviteMember: (emailList: string[]) => Promise<Member[] | undefined>;
  onRemoveMember: (memberId: string) => Promise<Member[] | undefined>;
  onCreateSharedLink: (
    payload: SharedLinkPayload
  ) => Promise<RoomSharedLink | undefined>;
  onUpdateSharedLink: (
    payload: SharedLinkPayload
  ) => Promise<RoomSharedLink | undefined>;
  onDeleteSharedLink: () => Promise<RoomSharedLink | undefined>;
};

type FormSchema = {
  roomName: string;
  emailList: { value: string }[];
};

const RoomSettingModal: React.FC<Props> = (props) => {
  const {
    room,
    sharedLink,
    isRoomOwner,
    canUpdateRoomName,
    canRemoveMember,
    canInviteMember,
    canCreateSharedLink,
    canRemoveSharedLink,
    isSendingInvitation,
    isCreatingSharedLink,
    isDeletingSharedLink,
    isUpdatingSharedLink,
    isOpen,
    onRequestClose,
    onInviteMember,
    onRemoveMember,
    onUpdateRoom,
    onCreateSharedLink,
    onDeleteSharedLink,
    onUpdateSharedLink,
  } = props;

  const {
    members,
    offset,
    perPage,
    totalCount,
    currentPage,
    isLoading: isLoadingMembers,
    onChangePage,
    onChangePerPage,
    onResetRoomPaginationData,
  } = useFetchRoomMembers(room.roomId);

  const [roomName, setRoomName] = useState<string>(room.name);
  const invitationLink = `https://${process.env.NEXT_PUBLIC_APP_HOST}/rooms/${room.roomId}/invitations/${sharedLink?.invitationCode}/link`;

  const {
    isOpen: isOpenDeleteDialog,
    onOpen: onOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
  } = useDisclosure();
  const { hasCopied, onCopy } = useClipboard(invitationLink);
  const { successToast, errorToast } = useToast();

  const { t } = useTranslation("rooms");

  const useFormReturn = useForm<FormSchema>({
    mode: "all",
    defaultValues: {
      roomName: "",
      emailList: [],
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = useFormReturn;
  const emailList = useWatch({ control, name: "emailList" }).map(
    (v) => v.value
  );

  const handleUpdateRoom = async () => {
    if (roomName.length < 1) {
      setRoomName(room.name);
      return errorToast({ title: t("errorEmptyRoomNameToast") });
    }
    if (roomName.length >= 60) {
      setRoomName(room.name);
      return errorToast({ title: t("roomNameMaxLengthError") });
    }
    try {
      await onUpdateRoom(roomName);
      successToast({
        title: t("successUpdateRoomNameToast", {
          room: roomName,
        }),
      });
    } catch (e) {
      errorToast({ title: t("failToast") });
    }
  };
  const handleSendInvitation = handleSubmit(async ({ emailList }) => {
    try {
      await onInviteMember(emailList.map((e) => e.value));
      setValue("emailList", []);
      successToast({ title: t("successSendInviteMailToast") });
    } catch (e) {
      errorToast({ title: t("failToast") });
    }
  });
  const handleCreateSharedLink = async () => {
    const expiredAt = getExpireDate(Math.floor(Date.now() / 1000), 3);
    try {
      await onCreateSharedLink({ payload: { expiredAt } });
      successToast({ title: t("successCreateRoomLinkToast") });
    } catch (e) {
      errorToast({ title: t("failToast") });
    }
  };
  const handleChangeExpireDate = async (e: ChangeEvent<HTMLSelectElement>) => {
    const days = Number(e.target.value);
    const expiredAt = getExpireDate(Math.floor(Date.now() / 1000), days);
    try {
      await onUpdateSharedLink({ payload: { expiredAt } });
      successToast({ title: t("successUpdateRoomLinkToast") });
    } catch (e) {
      errorToast({ title: t("failToast") });
    }
  };
  const handleDeleteSharedLink = async () => {
    try {
      await onDeleteSharedLink();
      successToast({ title: t("successDeleteRoomLinkToast") });
    } catch (e) {
      errorToast({ title: t("failToast") });
    }
  };
  const handleClose = () => {
    onResetRoomPaginationData();
    onRequestClose();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <ModalOverlay />
      <RoomDeleteDialogContainer
        roomId={room.roomId}
        isOpen={isOpenDeleteDialog}
        onClose={onCloseDeleteDialog}
        onComplete={onRequestClose}
      />
      <ModalContent>
        <ModalCloseButton mt={2.5} />
        <ModalHeader display="flex" w="calc(100% - 2.5rem)">
          <Editable
            value={roomName}
            display="flex"
            flexGrow={1}
            isDisabled={!canUpdateRoomName}
            onSubmit={handleUpdateRoom}
            onCancel={() => setRoomName(room.name)}
            submitOnBlur={false}
          >
            <EditablePreview />
            <EditableInput
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setRoomName(e.target.value)
              }
            />
            <Spacer />
            {canUpdateRoomName && <EditableControls {...props} />}
          </Editable>
        </ModalHeader>
        <Divider mb={3} />
        <ModalBody>
          <Container
            variant="unstyled"
            pl={4}
            pr={4}
            mb={3}
            maxHeight="20vh"
            overflowY="auto"
          >
            <VStack>
              {isLoadingMembers &&
                [...Array(2)].map((v, i) => (
                  <Flex
                    alignItems="center"
                    w="full"
                    key={`member-skeleton-${i}`}
                  >
                    <SkeletonCircle w={8} h={8} mr={3} />
                    <SkeletonText flexGrow={1} noOfLines={1} mr={3} />
                    <Skeleton h={5} w={16} borderRadius="full" mr={3} />
                  </Flex>
                ))}
              {!isLoadingMembers &&
                members
                  .sort((a, b) => {
                    if (a.displayName > b.displayName) return 1;
                    if (a.displayName < b.displayName) return -1;
                    return 0;
                  })
                  .sort((a, b) => {
                    if (a.status === "waiting" && b.status === "joined")
                      return 1;
                    if (a.status === "joined" && b.status === "waiting")
                      return -1;
                    return 0;
                  })
                  .filter((member) => {
                    if (members.length >= perPage) {
                      // perPageより今取得した人数が多い => waitingの邪魔な人が混じっている状態
                      return member.status !== "waiting";
                    } else {
                      return member;
                    }
                  })
                  .map((member) => (
                    <MemberListItem
                      key={`${room.roomId}_${member.bonbonUserId}_${member.emailAddress}`}
                      room={room}
                      member={member}
                      canRemoveMember={canRemoveMember}
                      onRemoveMember={onRemoveMember}
                    />
                  ))}
              )
            </VStack>
          </Container>
          <Pagination
            totalCount={totalCount}
            currentPage={currentPage}
            perPage={perPage}
            onChangePerPage={onChangePerPage}
            onClickPageLink={onChangePage}
          />
          <Divider mb={3} />

          {canInviteMember && (
            <form id="invite_emails" onSubmit={handleSendInvitation}>
              <InviteEmailListInput
                /*
                  型 'UseFormReturn<FormSchema, any>' を型 'UseFormReturn<BaseFieldValue, any>'
                  に割り当てられずエラーになるためキャストしている。
                  以下の issue の議論を参考に、部分的なフォームを作成する方法を実践したが、フォームの型に
                  Arrayが含まれる場合はうまくいかない模様。
                  https://github.com/react-hook-form/react-hook-form/issues/6726#issuecomment-939467832
                */
                form={
                  useFormReturn as unknown as UseFormReturn<
                    Pick<FormSchema, "emailList">
                  >
                }
                label={t("inviteEmailAddress")}
              />
              {/**単純にuseTranslation利用したらエラー */}
              <Flex>
                <Spacer />
                <Button
                  form="invite_emails"
                  type="submit"
                  isDisabled={!isValid || emailList.length < 1}
                  isLoading={isSendingInvitation}
                  loadingText={t("sendingInvitationEmail")}
                  colorScheme="aisekiYellow"
                  color="black"
                  mt={2}
                >
                  {t("sendInvitationMail")}
                </Button>
              </Flex>
            </form>
          )}

          <FormControl id="room-name" mb={3} hidden={!canCreateSharedLink}>
            <FormLabel fontWeight={"bold"}>{t("inviteByPublicLink")}</FormLabel>
            <Alert status="warning">
              <AlertIcon />
              {t("publicInvitationAlart")}
            </Alert>
            <Collapse in={!sharedLink} animateOpacity>
              <Flex>
                <Spacer />
                <Button
                  type="button"
                  onClick={handleCreateSharedLink}
                  isLoading={isCreatingSharedLink}
                  loadingText={t("generatingPublicLink")}
                  colorScheme="aisekiYellow"
                  color="black"
                  mt={2}
                >
                  <LinkIcon mr={2} />
                  {t("generatePublicLink")}
                </Button>
              </Flex>
            </Collapse>

            <Collapse in={!!sharedLink} animateOpacity>
              <InputGroup mt={2}>
                <InputLeftElement>
                  <LinkIcon />
                </InputLeftElement>
                <Input value={invitationLink} isReadOnly />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    aria-label={hasCopied ? "Copied" : "Copy"}
                    colorScheme="aisekiYellow"
                    color="black"
                    icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
                    onClick={onCopy}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex mt={2}>
                <Spacer />
                <FormLabel
                  htmlFor="shared_link_expiration"
                  fontSize="sm"
                  mr={2}
                  mt={1}
                >
                  {t("expired")}
                </FormLabel>
                <Select
                  id="shared_link_expiration"
                  onChange={handleChangeExpireDate}
                  disabled={isDeletingSharedLink}
                  size="sm"
                  w="6rem"
                  mr={4}
                >
                  {!!sharedLink &&
                    SHARED_LINK_EXPIRATION_OPTIONS.map((v) => {
                      const { createdAt, expiredAt } = sharedLink;
                      const days = Number(v);

                      return (
                        <option
                          key={`expiration_${v}`}
                          //selected={expiredAt == getExpireDate(createdAt, days)}
                          value={v}
                        >
                          {v > 0
                            ? t("organizations:expiredDay", { amount: v })
                            : t("organizations:unlimited")}
                        </option>
                      );
                    })}
                </Select>

                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={handleDeleteSharedLink}
                  disabled={isUpdatingSharedLink || !canRemoveSharedLink}
                  loadingText={t("deletingPublickLink")}
                  isLoading={isDeletingSharedLink}
                >
                  <DeleteIcon mr={1} />
                  {t("deletePublicLink")}
                </Button>
              </Flex>
            </Collapse>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button onClick={handleClose}>{t("close")}</Button>
            <Button
              colorScheme="red"
              onClick={onOpenDeleteDialog}
              hidden={!isRoomOwner}
            >
              {t("delete")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const EditableControls: React.FC<Props> = (props) => {
  const { isUpdatingRoom } = props;
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  if (isEditing) {
    return (
      <ButtonGroup ml={4}>
        <IconButton
          size="md"
          icon={<CheckIcon />}
          isLoading={isUpdatingRoom}
          {...getSubmitButtonProps()}
          aria-label="ルーム名の変更を保存する"
        />
        <IconButton
          size="md"
          icon={<CloseIcon />}
          isDisabled={isUpdatingRoom}
          {...getCancelButtonProps()}
          aria-label="ルーム名の変更をキャンセルする"
        />
      </ButtonGroup>
    );
  }

  return (
    <IconButton
      icon={<EditIcon />}
      {...getEditButtonProps()}
      aria-label="ルーム名を変更する"
    />
  );
};

type MemberListItemProps = Pick<
  Props,
  "room" | "canRemoveMember" | "onRemoveMember"
> & {
  member: Member;
};

const MemberListItem: React.FC<MemberListItemProps> = (props) => {
  const { room, member, canRemoveMember, onRemoveMember } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation("rooms");

  return (
    <Flex alignItems="center" w="full">
      <Avatar
        src={
          member.status === "joined" ? member.imageUrl || undefined : undefined
        }
        size="sm"
        mr={3}
      />
      <Text mr={3}>{member.displayName || member.emailAddress}</Text>
      {member.role === "owner" && <Badge>{t("owner")}</Badge>}
      <Spacer />
      <Box variant="unstyled">
        <Tag
          borderRadius="full"
          variant="solid"
          fontSize="xs"
          colorScheme={
            member.status === "joined" ? "aisekiGray" : "aisekiYellow"
          }
          color="aisekiBlack.500"
          mr={3}
        >
          <TagLabel>
            {member.status === "joined" ? t("joined") : t("waitToJoin")}
          </TagLabel>
        </Tag>
      </Box>
      <IconButton
        icon={<DeleteIcon />}
        size="sm"
        colorScheme="red"
        variant="outline"
        borderWidth={0}
        aria-label={`${member.displayName}をルームから削除する`}
        onClick={onOpen}
        hidden={!canRemoveMember}
        disabled={member.role === "owner" || member.status === "waiting"}
      />
      <MemberDeleteDialog
        room={room}
        member={member}
        onDelete={() => onRemoveMember(member.bonbonUserId)}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

type ContainerProps = Pick<Props, "isOpen" | "onRequestClose"> & {
  room: Room | undefined;
};

/**
 *
 * TODO : canRemoveInvitationLinkの調整
 * @param props
 * @returns
 */
export const RoomSettingModalContainer: React.FC<ContainerProps> = (props) => {
  const { room, isOpen, onRequestClose } = props;

  const { updateRoomMutate, isUpdating } = useUpdateRoom();
  const { sendInvitationMutate, isSending } = useSendInvitation(room?.roomId);
  const { deleteRoomMemberMutate, isDeleting } = useDeleteRoomMember(
    room?.roomId
  );

  const { sharedLink } = useFetchSharedLink(room?.roomId);
  const { mutate: createSharedLink, isCreating: isCreatingSharedLink } =
    useCreateSharedLink();
  const { mutate: updateSharedLink, isUpdating: isUpdatingSharedLink } =
    useUpdateSharedLink();
  const { mutate: deleteSharedLink, isDeleting: isDeletingSharedLink } =
    useDeleteSharedLink();

  if (!room) return null;

  return (
    <RoomSettingModal
      sharedLink={sharedLink}
      isRoomOwner={room.role === "owner"}
      canUpdateRoomName={["owner"].includes(room.role)}
      canRemoveMember={["owner"].includes(room.role)}
      canInviteMember={["owner", "member"].includes(room.role)}
      canCreateSharedLink={["owner", "member"].includes(room.role)}
      canRemoveSharedLink={["owner"].includes(room.role)}
      isUpdatingRoom={isUpdating}
      isSendingInvitation={isSending}
      isCreatingSharedLink={isCreatingSharedLink}
      isDeletingSharedLink={isDeletingSharedLink}
      isUpdatingSharedLink={isUpdatingSharedLink}
      room={room}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onUpdateRoom={(roomName: string) =>
        updateRoomMutate(room.roomId, { name: roomName })
      }
      onInviteMember={(emailList: string[]) =>
        sendInvitationMutate({ inviteUserEmailAddresses: emailList })
      }
      onRemoveMember={(memberId) => deleteRoomMemberMutate(memberId)}
      onCreateSharedLink={(payload) => createSharedLink(room.roomId, payload)}
      onDeleteSharedLink={() => deleteSharedLink(room.roomId)}
      onUpdateSharedLink={(payload) => updateSharedLink(room.roomId, payload)}
    />
  );
};
