import { Tooltip, IconButton, IconButtonProps } from "@chakra-ui/react";

type tooltipButtonProps = IconButtonProps & {
  label: string;
};

const TooltipButton = (props: tooltipButtonProps) => {
  return (
    <Tooltip label={props.label}>
      <IconButton {...props}></IconButton>
    </Tooltip>
  );
};

export default TooltipButton;
