import { useTranslation } from "react-i18next";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Text,
  Avatar,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  Link,
  Center,
  ListItem,
  Box,
  List,
  Divider,
  PopoverFooter,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { MdExitToApp } from "react-icons/md";
import { useAuthDispatch } from "@bonbon/data-access-auth";
import { useFeatureState } from "@bonbon/feature-auth";

type UserButtonProps = {
  displayName: string;
  userIconUrl?: string;
  userEmailAddress?: string;
};
const UserButton = ({
  displayName,
  userIconUrl,
  userEmailAddress,
}: UserButtonProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { accountSettingPath } = useFeatureState();
  const { handleSignOut } = useAuthDispatch();
  return (
    <Popover>
      <PopoverTrigger>
        <Flex as="a" alignItems="center" onClick={onOpen}>
          <Text
            fontSize="sm"
            maxW="10rem"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            ml={4}
            mr={6}
          >
            {displayName}
          </Text>
          <Avatar
            name={displayName}
            src={userIconUrl}
            textColor="black"
            size="sm"
            bg="gray.100"
          />
        </Flex>
      </PopoverTrigger>
      <PopoverContent w={400} mr={8}>
        <PopoverHeader h={12}>
          <Center justifyContent={"space-between"}>
            <PopoverCloseButton w={10} h={10} />
            <Text fontSize={20}>アカウント</Text>
          </Center>
        </PopoverHeader>
        <PopoverBody w={"full"}>
          <Box p={4}>
            <Flex>
              <Avatar
                name={displayName}
                src={userIconUrl}
                textColor="black"
                size="md"
                bg="gray.100"
              />
              <List>
                <ListItem>
                  <Text
                    fontSize="lg"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    ml={4}
                  >
                    {displayName}
                  </Text>
                </ListItem>
                <ListItem>
                  <Text
                    fontSize="sm"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    ml={4}
                  >
                    {userEmailAddress}
                  </Text>
                </ListItem>
              </List>
            </Flex>
            <Box mt={4} border="1px" borderColor="gray.200" p={2}>
              <a href={accountSettingPath} target="_blank" rel="noreferrer">
                <ExternalLinkIcon mx="2px" /> BonBonIdを管理する
              </a>
            </Box>
          </Box>
          <Divider />
          <List w={"full"} _hover={{ backgroundColor: "#DDDDDD" }}>
            <ListItem w={"full"}>
              <Center
                justifyContent={"left"}
                ml={4}
                p={1}
                onClick={handleSignOut}
                cursor={"pointer"}
              >
                <Icon as={MdExitToApp} w={6} h={6} />
                <Text ml={4} fontSize={18}>
                  {t("account:signOut")}
                </Text>
              </Center>
            </ListItem>
          </List>
          <Divider />
        </PopoverBody>
        <PopoverFooter>
          <HStack justifyContent={"center"} spacing={8} my={2}>
            <Link href="https://bon-bon.co.jp/privacy-policy/" isExternal>
              <Text target="_blank" fontSize="md">
                {t("common:privacyPolicy")}
              </Text>
            </Link>
            <Link href="/terms" isExternal>
              <Text target="_blank" fontSize="md">
                {t("common:termOfUse")}
              </Text>
            </Link>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default UserButton;
